/*eslint angular/di: [2,"array"]*/

export default angular
  .module('check.identity.service', [])

  /* @ngInject */
  .factory('CheckIdentityService', ['$http', function ($http) {
    const service = {
      loadRegex
    };
    return service;

    ////////////
    async function loadRegex(localization) {
      return $http.get('Octave/Config', {
        params: {
          configPath: `check.identity.config:${localization}`
        }
      })
        .then(function (response) {
          return response.data.results;
        })
        .catch(function () {
          return {};
        });
    }
  }]);
