export default angular
  .module('service.date', [])

  /* @ngInject */
  .factory('DateService', function () {
    const service = {
      stringToDate,
      dateToISO,
      today,
      tomorrow,
      nextYear,
      dateDay,
      minISODate
    };
    return service;

    ////////////
    function stringToDate(value) {
      return new Date(value.split('.')[0].replace('T', ' ').replace('Z', '').replace(/-/g, '/'));
    }
    function dateToISO(value) {
      if (!value) return minISODate();
      return new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate())).toISOString();
    }
    function today() {
      const now = new Date();
      return new Date(now.getFullYear(), now.getMonth(), now.getDate());
    }
    function tomorrow() {
      const now = new Date();
      return new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    }
    function nextYear() {
      const now = new Date();
      return new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());
    }
    function dateDay(date) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }
    function minISODate() {
      return new Date('0001-01-01T00:00:00.000Z').toISOString();
    }
  });
