export default angular
  .module('directive.fb-connect', [])

  /* @ngInject */
  .directive('fbConnect', function () {
    return {
      restrict: 'A',
      bindToController: {
        content: '@'
      },
      /* @ngInject */
      controller: function ($scope, $injector, AppService) {
        const ctrl = this;

        ctrl.$onDestroy = () => {};

        ctrl.$onInit = async () => {
          const params = await AppService.getParams();
          if (params.Localization.UseFacebookConnect && !params.IsLogged) {
            const response = await import(/* webpackChunkName: "fb-connect" */ 'import/js/directives/fb-connect/fb-connect.js');
            $injector.loadNewModules([response.default.name]);
            const service = $injector.get('ImportFbConnect');
            service.init(ctrl, $scope);
          }
        };
      },
      controllerAs: 'fbConnectCtrl'
    };
  });
