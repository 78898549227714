import { merge, kebabCase } from 'lodash-es';
import 'Modules/Authentication/import/toast_authentication.tpl';

export default angular
  .module('directive.minilogin', [])

  /* @ngInject */
  .directive('miniLogin', function () {
    return {
      restrict: 'E',
      bindToController: {
        title: '@',
        titleVisitor: '@',
        titleLogin: '@',
        titleLogout: '@',
        device: '<'
      },
      controllerAs: 'miniLoginCtrl',
      /* @ngInject */
      controller: function (
        $injector,
        $scope,
        $rootScope,
        $element,
        $document,
        $location,
        $route,
        $timeout,
        toastr,
        AccountService,
        LogService,
        AppService,
        RoutingService,
        ModalService
      ) {
        const ctrl = this;

        ctrl.links = AccountService.getLinks();

        ctrl.$onInit = async () => {
          ctrl.clientIsActive = true;
          const params = await AppService.getParams();
          if (params.Agent && params.Mobility.AgentId !== 0) {
            ctrl.clientIsActive = params.Mobility.Profile.AllModules.find(p => p.ModuleKey === 'clients')?.IsActive;
          }
          ctrl.visitor = ctrl.titleVisitor;
          update();
        };

        const $body = $(document.body);
        $body.addClass('offcanvas');
        const $header = $('#header');

        ctrl.toggle = () => {
          toggle();
        };

        ctrl.login = async event => {
          // La fonction "toggle()" qui permet d’afficher ou de masquer la fenêtre des menus du compte client est appelée si l’utilisateur est en affichage mobile, qu’il est connecté et que l’élément HTML cliqué n’est pas un des menus de cette fenêtre.
          const navMenuElement = $('#navmenu-fixed-right > .content')[0];
          if (ctrl.device.mobile && ctrl.isLogged && (navMenuElement && !navMenuElement.contains(event.target))) {
            toggle();
            return;
          }
          if (ctrl.isLogged) return;

          event.preventDefault();

          const params = await AppService.getParams();
          if (!params.Agent) {
            ModalService.show('/Template/Authentication/ModalAuthentication', null, null, 'loginModalCtrl');
          } else {
            showMobiClient();
          }
        };

        ctrl.logout = async () => {
          const params = await AppService.getParams();
          if (params.Agent && params.Visitor.IDCard !== 3) {
            showMobiClient();
            return;
          }
          $timeout(() => {
            ctrl.visitor = ctrl.title;
            ctrl.isLogged = false;
          });
          toggle();
          $rootScope.$broadcast('showPageLoader', true);
          LogService.logout()
            .then(response => {
              if (response.status === 'OK') {
                AppService.updateParams(merge(response.VisitorContext, { IsLogged: false }));
                $rootScope.$broadcast('logUpdate', false);
                update(true);

                toastr.success('', ctrl.titleLogout, {
                  allowHtml: true,
                  extraData: {
                    template: 'Modules/Authentication/import/toast_authentication.tpl'
                  }
                });
                if ($route.current && $route.current.resolve && $route.current.resolve.authorize) {
                  $location.path('/');
                } else {
                  RoutingService.forceReload();
                }
              }
              $rootScope.$broadcast('showPageLoader', false);
            })
            .catch(error => {
              console.error(error);
            });
        };

        // Functions

        $scope.$on('userUpdate', () => {
          update(true);
        });

        $scope.$on('logUpdate', (event, value) => {
          if (value) {
            toastr.success('', ctrl.titleLogin, {
              allowHtml: true,
              extraData: {
                template: 'Modules/Authentication/import/toast_authentication.tpl'
              }
            });
          }
          update();
        });

        $scope.$on('logout', () => {
          ctrl.logout();
        });

        // Functions

        function toggle() {
          if ($body.hasClass('offcanvas-right')) {
            $body.removeClass('offcanvas-right');
            $document.off('click', onBodyClick);
          } else {
            $('.navmenu-fixed-right').css({ top: $header.position().top + $header.height() - $(window).scrollTop() });
            $body.addClass('offcanvas-right');
            $document.on('click', onBodyClick);
          }
        }

        function onBodyClick(event) {
          if (!$element[0].contains(event.target)) {
            toggle();
          }
        }

        function update(refresh) {
          AppService.getParams(refresh)
            .then(params => {
              ctrl.isLogged = params.IsLogged;
              ctrl.sellerUrl = null;
              if (ctrl.isLogged) {
                ctrl.visitor = params.Visitor.FullName;
                if (params.Visitor.CardType.KeyCardType === 'PERSONNEL') {
                  ctrl.sellerUrl = `/${kebabCase(params.Visitor.FirstName + params.Visitor.LastName.slice(0, 1))}-v-${params.Visitor.IDCard}`;
                }
              }
              AccountService.setManagement(ctrl);
              if (refresh) {
                $rootScope.$broadcast('cartUpdate');
              }
              !$rootScope.$$phase && $scope.$digest();
            })
            .catch(error => {
              console.error(error);
            });
        }

        async function showMobiClient() {
          const service = $injector.get('MtyService');
          const lexic = (await service.getTranslate()).web;

          ModalService.show(
            '/Template/MTYClients/ModalClients',
            {
              title: lexic.MOBI_Clients_Connection,
              text: lexic.MOBI_Clients_Choice,
              isWeb: true,
              buttonPrimary: 'confirm',
              device: $scope.device
            },
            null,
            'modalMtyClientsCtrl',
            'clientsCtrl',
            null,
            null,
            null,
            () => {
              $rootScope.$broadcast('showPageLoader', false);
            }
          );
        }
      }
    };
  });
