export default angular
  .module('directive.flockler', [])

  /* @ngInject */
  .directive('flockler', function () {
    return {
      restrict: 'A',
      bindToController: {
        content: '@'
      },
      /* @ngInject */
      controller: function ($scope, $injector) {
        const ctrl = this;

        ctrl.$onDestroy = () => {};

        ctrl.$onInit = async () => {
          const response = await import(/* webpackChunkName: "flockler" */ 'import/js/directives/flockler/flockler.js');
          $injector.loadNewModules([response.default.name]);
          const service = $injector.get('ImportFlockler');
          service.init(ctrl, $scope);
        };
      },
      controllerAs: 'flocklerCtrl'
    };
  });
