export default angular
  .module('component.customization', [])

  .component('customization', {
    require: {
      productCtrl: '^^productDetail'
    },
    bindings: {
      model: '<',
      tooltip: '@'
    },
    /* @ngInject */
    templateUrl: function ($sce) {
      return $sce.trustAsResourceUrl('/Template/Product/ProductCustomization');
    },
    /* @ngInject */
    controller: function ($element, $injector) {
      const ctrl = this;

      ctrl.$onInit = async () => {
        const response = await import(/* webpackChunkName: "product" */ 'Modules/Product/import/product.js');
        $injector.loadNewModules([response.default.name]);
        const service = $injector.get('ImportProduct');
        service.initCustomization(ctrl, $element);
      };
    }
  });
