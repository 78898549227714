export default angular
  .module('confirmation', [])

  .directive('cartConfirmation', function () {
    return {
      restrict: 'A',
      controllerAs: 'cartCtrl',
      /* @ngInject */
      controller: function ($rootScope) {
        const ctrl = this;

        ctrl.$onInit = () => {
          $rootScope.$broadcast('userUpdate');
          import(/* webpackChunkName: "confirmation" */ 'Modules/Payment/import/confirmation.less');
        };
      }
    };
  });
