export default angular
  .module('service.string', [])

  /* @ngInject */
  .factory('StringService', function ($sce) {
    return {
      findMatchString: (value, str) => {
        if (!value) {
          return;
        }
        if (!value.match || !value.replace) {
          value = value.toString();
        }
        let matches, reg;

        const words = str.split(' ');
        let tmp;
        words.forEach(word => {
          tmp = word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
          if (tmp.length > 2) {
            reg = new RegExp(tmp, 'ig');
            matches = value.match(reg);
            if (matches) {
              value = value.replace(reg, `##${matches[0]}#/#`);
            }
          }
        });

        const open = new RegExp('##', 'g');
        const close = new RegExp('#/#', 'g');
        value = value.replace(open, '<span class="match">').replace(close, '</span>');
        return $sce.trustAsHtml(value).valueOf();
      }
    };
  });
