export default angular
  .module('directive.togglePasswordVisibility', [])

  .directive('togglePasswordVisibility', function () {
    return {
      restrict: 'A',
      /* @ngInject */
      controller: function ($scope, $timeout) {
        $scope.togglePasswordVisibility = function (inputPasswordFieldID, triggerElementID) {
          const inputPasswordField = angular.element((`#${inputPasswordFieldID}`));
          const triggerElement = angular.element((`#${triggerElementID}`));

          function showPassword() {
            triggerElement.removeClass('glyphicon-open-eye');
            triggerElement.addClass('glyphicon-closed-eye');
            inputPasswordField.attr('type', 'text');

            $timeout(() => {
              hidePassword();
            }, 15000);

          }

          function hidePassword() {
            triggerElement.removeClass('glyphicon-closed-eye');
            triggerElement.addClass('glyphicon-open-eye');
            inputPasswordField.attr('type', 'password');
          }

          if (inputPasswordField.attr('type') === 'password') {
            showPassword();
          } else {
            hidePassword();
          }
        };
      }
    };
  });
