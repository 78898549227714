export default angular
  .module('menu.service', [])

  /* @ngInject */
  .factory('MenuService', function () {
    const service = {
      showMenuHover,
      setSubMenuHoverPosition
    };
    return service;

    function showMenuHover(menuHover, subMenuHover, fullHeight) {
      const menuByStep = menuHover.prev().children().first();
      const menuByStepHeight = menuByStep.outerHeight();
      const menuByStepMargins = menuByStepHeight - menuByStep.height();
      const hoverMargins = menuHover.outerHeight() - menuHover.height();
      menuByStep.height(fullHeight - menuByStepMargins);
      if (menuByStep.height() !== 0) menuHover.height(fullHeight - hoverMargins).addClass('is-active');

      if (subMenuHover && subMenuHover.length > 0) {
        const subMenuHoverMargins = subMenuHover.outerHeight() - subMenuHover.height();
        setSubMenuHoverPosition(subMenuHover, menuHover, true);
        subMenuHover
          .height(fullHeight - subMenuHoverMargins - 4)
          .width(menuHover.width())
          .addClass('is-active');

        menuHover.removeClass('is-active');
      }
    }

    function setSubMenuHoverPosition(subMenuHover, menuHover, forceLeftPosition = false) {
      const left = forceLeftPosition ? menuHover.offset().left : subMenuHover.css('left');
      subMenuHover.css({ top: menuHover.offset().top + 4 - $(window).scrollTop(), left });
    }
  });
