import 'import/js/components/addtocart/component.addtocart.tpl';

export default angular
  .module('component.addtocart', [])

  .component('buttonAddToCart', {
    require: {
      productCtrl: '?^^productDetail',
      comparatorCtrl: '?^^comparator'
    },
    bindings: {
      isProductButton: '<?',
      isFinderButton: '<?',
      isCompositionType: '<?',
      idCompositionType: '<?',
      isCartButton: '<?',
      action: '&?',
      btnClass: '@',
      idLine: '<?',
      commentLine: '@?',
      id: '<idProduct',
      isGift: '<',
      productAvailability: '@?',
      productAvailabilityMessage: '@?',
      productQuantity: '<?',
      isPrimary: '<',
      addToCartActive: '<?',
      allowAddToCart: '<',
      min: '@',
      step: '@',
      price: '<productPrice',
      designation: '@productDesignation',
      img: '@productImg',
      distrimageSrc: '@productDistrimageSrc',
      url: '@productUrl',
      titleAdd: '@?',
      titleAlert: '@?',
      tooltip: '@',
      modalCtrl: '<',
      device: '<'
    },
    templateUrl: 'import/js/components/addtocart/component.addtocart.tpl',
    /* @ngInject */
    controller: function ($scope, $element, $injector) {
      const ctrl = this;

      ctrl.$onInit = async () => {
        const response = await import(/* webpackChunkName: "component.addtocart-init" */ 'import/js/components/addtocart/component.addtocart.init.js');
        $injector.loadNewModules([response.default.name]);
        const service = $injector.get('InitComponentAddtocart');
        service.init(ctrl, $scope);
      };
    }
  });
