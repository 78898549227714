/**
 * Fonction utilitaire permettant d'aller chercher des propriétés dans un objet
 * en fonction d'un clé donnée
 * @param {string} key La clé de la propriété dont on veut chercher la valeur
 * @param {any} object L'objet dans lequel chercher
 */
export function getObjectPropertyFromKey(key, object) {
  if (key.includes('.')) {
    const [parentKey, ...childrenKey] = key.split('.');

    //on teste de récupérer la valeur avec la clé telle quelle ou en lowercase
    const value = object && (object[parentKey] || object[parentKey.toLowerCase()]);

    return getObjectPropertyFromKey(childrenKey.join('.'), value);
  }

  return object && (object[key] || object[key.toLowerCase()]);
}
