export default angular
  .module('directive.capBuilding', [])
  /**
   * @class angular.capadresse.capBuilding
   * @memberOf angular.capadresse
   * @desc Directive : A ajouter au champ d'adresse "bâtiment", permet sa saisie en automatique via Capadress
   */
  .directive('capBuilding', function () {
    return {
      restrict: 'A',
      require: ['ngModel', '?^^capForm'],
      link: (scope, _elt, _attrs, controllers) => {
        const [modelCtrl, formCtrl] = controllers;

        if (!formCtrl) {
          return;
        }

        // When building is hidden behind a ngIf of uibDropdown
        if ('capBuilding' in formCtrl) {
          setValue(formCtrl.capBuilding.value);
        }

        formCtrl.capBuilding = {
          setValue,
          clear
        };

        /**
         * @function setValue
         * @memberOf capBuilding
         * @description Set ngModel value to fill the field
         * @param {string} value
         */
        function setValue(value) {
          modelCtrl.$setViewValue(value);
          modelCtrl.$render();
        }

        /**
         * Programatically clear the field;
         */
        function clear() {
          modelCtrl.$setViewValue('');
          modelCtrl.$render();
        }
      }
    };
  });
