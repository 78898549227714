export default angular
  .module('directive.page.head', [])

  /* @ngInject */
  .directive('pageHead', function ($location, $window, RoutingService) {
    return {
      restrict: 'E',
      scope: {
        metadata: '@'
      },
      link: function (scope, element) {
        setTimeout(() => {
          const routing = RoutingService.get();
          if (!routing.from) return;

          const metadata = scope.$eval(scope.metadata);

          if (metadata.url && $window.location.pathname !== '/' && metadata?.url !== `${$window.location.origin}${$window.location.pathname}`) {
            $location.update({ path: metadata.url.split(`${$window.location.origin}/`)[1] });
          }

          $window.document.title = metadata.title;

          if (angular.isDefined(metadata.description) && metadata.description !== '') {
            if (!$('meta[name="description"]').length) {
              $(`<meta name="description" content="${metadata.description}" />`).insertAfter('title');
            } else {
              $('meta[name="description"]').attr('content', metadata.description);
            }
          } else {
            $('meta[name="description"]').remove();
          }

          if (angular.isUndefined(metadata.checkout)) {
            $(document.body).removeClass('checkout');
          } else {
            $(document.body).addClass('checkout');
          }
          scope.$emit('pageCheckout', angular.isDefined(metadata.checkout));

          if (angular.isUndefined(metadata.homepage)) {
            $(document.body).removeClass('homepage');
          } else {
            $(document.body).addClass('homepage');
          }
          scope.$emit('homepage', angular.isDefined(metadata.homepage));
        });
      }
    };
  });
