export default angular
  .module('directive.kit', [])

  .component('kitComponents', {
    require: {
      productCtrl: '^^productDetail'
    },
    bindings: {
      idKit: '@',
      idGroup: '@',
      device: '<'
    },
    /* @ngInject */
    templateUrl: function ($sce) {
      return $sce.trustAsResourceUrl('/Template/Product/KitComponents');
    },
    controllerAs: 'kitCtrl',
    /* @ngInject */
    controller: function ($element, $injector) {
      const ctrl = this;

      ctrl.$onInit = async () => {
        const response = await import(/* webpackChunkName: "product" */ 'Modules/Product/import/product.js');
        $injector.loadNewModules([response.default.name]);
        const service = $injector.get('ImportProduct');
        service.initKit(ctrl, $element);
      };
    }
  });
