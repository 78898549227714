export default angular
  .module('service.youtube', [])

  /* @ngInject */
  .factory('YoutubeService', function () {
    const service = {
      getId,
      getThumbnail
    };
    return service;

    ////////////
    function getId(url) {
      if (/youtu\.?be/.test(url)) {
        // Look first for known patterns
        let i;
        const patterns = [
          /youtu\.be\/([^#\&\?]{11})/, // youtu.be/<id>
          /\?v=([^#\&\?]{11})/, // ?v=<id>
          /\&v=([^#\&\?]{11})/, // &v=<id>
          /embed\/([^#\&\?]{11})/, // embed/<id>
          /\/v\/([^#\&\?]{11})/ // /v/<id>
        ];

        // If any pattern matches, return the ID
        for (i = 0; i < patterns.length; ++i) {
          if (patterns[i].test(url)) {
            return patterns[i].exec(url)[1];
          }
        }

        // If that fails, break it apart by certain characters and look
        // for the 11 character key
        const tokens = url.split(/[\/\&\?=#\.\s]/g);
        for (i = 0; i < tokens.length; ++i) {
          if (/^[^#\&\?]{11}$/.test(tokens[i])) {
            return tokens[i];
          }
        }
      }
      return null;
    }

    function getThumbnail(id) {
      return {
        default: {
          url: `http://img.youtube.com/vi/${id}/default.jpg`,
          width: 120,
          height: 90
        },
        medium: {
          url: `http://img.youtube.com/vi/${id}/mqdefault.jpg`,
          width: 320,
          height: 180
        },
        high: {
          url: `http://img.youtube.com/vi/${id}/hqdefault.jpg`,
          width: 480,
          height: 360
        }
      };
    }
  });
