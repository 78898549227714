export default angular
  .module('service.gmap', [])

  /* @ngInject */
  .factory('GMaps', function ($window, $q, $cacheFactory, AppService) {
    const addressCache = $cacheFactory('addressCache');
    const gpsCache = $cacheFactory('gpsCache');
    const getDefer = $q.defer();
    const service = {
      get,
      getGeoCode,
      getGeoCodeByGps
    };
    return service;

    ////////////
    function get() {
      if (angular.isUndefined(window.google) || angular.isUndefined(window.google.maps)) {
        AppService.getParams()
          .then(params => {
            const url = `https://maps.googleapis.com/maps/api/js?key=${params.Localization.GMAPKey}&libraries=geometry,places&callback=`;
            $window.gMapsInit = () => {
              getDefer.resolve();
            };
            const script = document.createElement('script');
            script.src = `${url}gMapsInit`;
            document.body.appendChild(script);
          })
          .catch(error => {
            console.error(error);
          });
      }
      return getDefer.promise;
    }

    function getGeoCode(address) {
      const defer = $q.defer();
      get()
        .then(() => {
          const cache = addressCache.get(address);
          if (cache) {
            defer.resolve(cache);
          } else {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ address }, (results, status) => {
              if (status === 'OK') {
                let country = results[0].address_components.filter(item => item.types[0] === 'country');
                country = country[0].long_name;
                const result = {
                  Latitude: results[0].geometry.location.lat(),
                  Longitude: results[0].geometry.location.lng(),
                  Country: country
                };
                addressCache.put(address, result);
                defer.resolve(result);
              } else {
                defer.reject({
                  status
                });
              }
            });
          }
        })
        .catch(error => error);
      return defer.promise;
    }

    function getGeoCodeByGps(gps) {
      const defer = $q.defer();
      const strGps = angular.toJson(gps);
      get()
        .then(() => {
          const cache = gpsCache.get(strGps);
          if (cache) {
            defer.resolve(cache);
          } else {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ location: { lat: gps.lat, lng: gps.lng } }, (results, status) => {
              if (status === 'OK') {
                const country = results[0].address_components.filter(item => item.types[0] === 'country');
                const locality = results[0].address_components.filter(item => item.types[0] === 'locality' || 'postal_town');
                const postalcode = results[0].address_components.filter(item => item.types[0] === 'postal_code');
                const streetnumber = results[0].address_components.filter(item => item.types[0] === 'street_number');
                const route = results[0].address_components.filter(item => item.types[0] === 'route');
                let address = '';
                if (streetnumber.length) {
                  address = streetnumber[0].long_name;
                }
                if (route.length) {
                  address = `${address} ${route[0].long_name}`;
                }
                const result = {
                  gps: {
                    Latitude: results[0].geometry.location.lat(),
                    Longitude: results[0].geometry.location.lng(),
                    Country: country[0].long_name,
                    CountryCode: country[0].short_name,
                    PostCode: postalcode[0].long_name,
                    City: locality[0].long_name,
                    Address: address
                  },
                  address: results[0].formatted_address
                };
                gpsCache.put(strGps, result);
                defer.resolve(result);
              } else {
                defer.reject('error');
              }
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
      return defer.promise;
    }
  });
