import 'Modules/Quotation/import/quotation.less';

export default angular
  .module('service.quotation', [])

  /* @ngInject */
  .factory('QuotationService', function (HttpService) {
    const service = {
      getQuotations,
      addToQuotation,
      removeQuotationLine
    };
    return service;

    ////////////
    function getQuotations() {
      return HttpService.get({
        url: '/AccountQuotations/Get',
        cache: false
      });
    }
    function addToQuotation(idProduct, idQuotation, object, quantity) {
      return HttpService.post({
        url: '/Quotation/AddToQuotation',
        data: {
          IdProduct: idProduct,
          IdQuotation: idQuotation,
          Object: object,
          Quantity: quantity
        }
      });
    }
    function removeQuotationLine(idLine, idQuotation) {
      return HttpService.post({
        url: '/Quotation/RemoveQuotationLine',
        data: {
          IdLine: idLine,
          IdQuotation: idQuotation
        }
      });
    }
  });
