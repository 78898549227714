export default angular
  .module('directive.product.gallery', [])

  /* @ngInject */
  .directive('productGallery', function ($injector) {
    return {
      restrict: 'A',
      link: async (scope, element, attrs) => {
        const response = await import(/* webpackChunkName: "product" */ 'Modules/Product/import/product.js');
        $injector.loadNewModules([response.default.name]);
        const service = $injector.get('ImportProduct');
        service.initGallery(scope, element, attrs);
      }
    };
  });
