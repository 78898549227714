export default angular
  .module('directive.bottom-section', [])
  /**
   * @class specifics.product-bottom.bottomSection
   * @memberOf specifics.product-bottom
   * @desc Allows to pass lexicon informations to the parent productBottom component
   * @example
   * <product-bottom device="device">
   *    <bottom-slot bottom-section="slot" label="@MLG["AnyLexiconKey"]"> Content </bottom-slot>
   * </product-bottom>
   */
  .directive('bottomSection', function () {
    return {
      restrict: 'A',
      require: '^productBottom',
      link: (scope, _elt, attrs, productBottomCtrl) => {
        productBottomCtrl.addSection({
          slot: attrs.bottomSection,
          label: attrs.label
        });
      }
    };
  });
