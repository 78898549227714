import 'import/js/components/addtocart/component.addtocart.modulo.tpl';
import 'import/js/components/addtocart/component.addtocart.modulo.less';

export default angular
  .module('component.addtocart.modulo', [])

  .component('inputAddToCartModulo', {
    require: {
      productCtrl: '?^^productDetail'
    },
    bindings: {
      isProductButton: '<?',
      isFinderButton: '<?',
      action: '&?',
      btnClass: '@',
      idLine: '<?',
      commentLine: '@?',
      id: '<idProduct',
      idStatus: '<',
      productQuantity: '<?',
      isPrimary: '<',
      availableQuantity: '<',
      availability: '<',
      addToCartActive: '<?',
      allowAddToCart: '<',
      price: '<productPrice',
      designation: '@productDesignation',
      img: '@productImg',
      url: '@productUrl',
      titleAdd: '@?',
      titleAlert: '@?',
      tooltip: '@',
      btnTooltip: '@',
      modalCtrl: '<',
      unit: '@',
      moduloFormat: '@',
      moduloCaption: '@',
      moduloTotal: '@',
      modalTitle: '@?',
      showQteMin: '<',
      labelQteMin: '@?',
      labelQteMax: '@?',
      labelQteVente: '@?',
      labelPlusInfo: '@?',
      min: '<',
      step: '<',
      device: '<'
    },
    templateUrl: 'import/js/components/addtocart/component.addtocart.modulo.tpl',
    /* @ngInject */
    controller: function ($scope, $injector) {
      const ctrl = this;

      ctrl.$onDestroy = () => {};

      ctrl.$onInit = async () => {
        const response = await import(/* webpackChunkName: "addtocart.modulo.init" */ 'import/js/components/addtocart/component.addtocart.modulo.init.js');
        $injector.loadNewModules([response.default.name]);
        const service = $injector.get('InitAddToCartModulo');
        service.init(ctrl, $scope);
      };
    }
  });
