import 'import/js/directives/video/video-player.less';

export default angular
  .module('directive.video.player', [])

  /* @ngInject */
  .directive('videoPlayer', function ($timeout) {
    return {
      restrict: 'E',
      scope: {
        url: '@',
        autoplay: '<'
      },
      template: '<span us-spinner spinner-theme="small" spinner-on="showLoader"></span><div class="player"></div>',
      link: async (scope, element) => {
        scope.showLoader = true;

        await import(/* webpackChunkName: "jquery.pplayer" */ 'import/js/plugins/jquery.pplayer.js');

        init();

        function init() {
          scope.showLoader = false;
          const id = getYouTubeIdFromUrl(scope.url);
          element.children('.player').pPlayer({
            youtubeVideoId: id,
            autoplay: scope.autoplay ? 1 : 0,
            adapter: 'Youtube',
            showinfo: 0,
            features: ['playpause', 'progress', 'timer', 'quality', 'mute', 'fullscreen'],
            resizeVideo: false,
            afterInit: () => {
              $timeout(() => {
                scope.showLoader = false;
              });
              const $mainVideo = element.parent().parent();
              if ($mainVideo.hasClass('main-video')) {
                $mainVideo.children('.inner').addClass('in');
              }
            }
          });
        }

        function getYouTubeIdFromUrl(youtubeUrl) {
          const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
          const match = youtubeUrl.match(regExp);
          if (match && match[2].length === 11) {
            return match[2];
          }
          return false;
        }
      }
    };
  });
