export default angular
  .module('directive.flip', [])

  /* @ngInject */
  .directive('flip', function () {
    return {
      restrict: 'A',
      scope: true,
      link: (scope, element, attrs) => {
        const flip = attrs.flip;

        setTimeout(resize);

        scope.toggle = () => {
          scope.flipped = !scope.flipped;
          resize();
        };

        scope.$on(`toggle_${flip}`, () => {
          scope.toggle();
        });

        scope.$on('watchResize', () => {
          const time = new Date().getTime();
          const inter = setInterval(() => {
            resize();
            const ntime = new Date().getTime();
            if (ntime > time + 700) {
              clearInterval(inter);
            }
          }, 50);
        });

        function resize() {
          scope.$emit('resized');
          const selector = !scope.flipped ? '.front' : '.back';
          const height = Math.floor(element.find(selector).children().outerHeight(true)) + 1;
          element.css('min-height', height);
        }
      }
    };
  });
