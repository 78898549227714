import 'Modules/Capency/components/component.capdropdown.tpl';
import 'Modules/Capency/components/component.capdropdown.less';
import { CapDropdown } from '../helpers/capdropdown';

/**
 * =======    WARNING INCOMPLETE / EXAMPLE    =======
 *
 * @class angular.capency.capValidatePhone
 * @desc Composant angularJS : list the phone number possible formats.
 * @memberOf angular.capency
 */
/* @ngInject */
class CapValidatePhone extends CapDropdown {
  constructor($scope, $element, $transclude, $timeout, capencyService) {
    super($scope, $element, $transclude, $timeout);
    this.capService = capencyService;
  }

  $onInit() {
    if (!this.formCtrl) {
      this.disable();
      return;
    }

    this.formCtrl.capValidatePhone = {
      setOption: this.setOption.bind(this),
      getOption: () => this.selection,
      clear: this.clear.bind(this)
    };

    this.initField();
  }

  /**
   * When form is already filled, link with capAdress Values
   */
  initField() {
    if (!this.modelCtrl.$modelValue) {
      return;
    }

    this.selection = { phone: this.modelCtrl.$modelValue };

    this.modelCtrl.$viewChangeListeners.unshift(() => {
      const afterSelection = this.modelCtrl.$viewValue === this.parseOption(this.selection);

      if (afterSelection || this.cleared) {
        return;
      }
    });
  }

  /**
   * Fetch list of valid phone formats from Capaddress
   * @param {string} userInput
   */
  async fetchList(userInput) {
    const country = this.formCtrl.capCountry.$modelValue;
    const countryId = angular.isObject(country) ? country.IDCountry : country;

    return this.capService.validPhoneList(userInput, countryId);
  }

  /**
   * What to display from the option on the dropdown
   * @param {CapAddressOption} option
   */
  displayOption(option) {
    return option.sTel;
  }

  /**
   * Parse the value to save in the ngModel from the capAdress result
   * @param {CapAddressOption} option
   */
  parseOption(option) {
    if (option === undefined) {
      return;
    }

    return option.sTel;
  }

  /**
   * Interface to other cap fields that can update this one's value
   * @param {CapAddressOption} option
   * @param {number} index optionnal: index of the option in options list
   */
  setOption(option, index) {
    super.select(option, index);
  }

  /**
   * Finds in select's options list, the index of given value
   * @param {Object} value
   */
  findIndex(value) {
    return this.options.findIndex(option => {
      if ('sTel' in option) {
        return option.sTel === value.sTel;
      }

      // Fallback
      return this.parseOption(option) === this.parseOption(value);
    });
  }
}

export default angular
  .module('component.capValidatePhone', [])
  .controller('capValidatePhoneCtrl', CapValidatePhone)
  .component('capValidatePhone', {
    templateUrl: 'Modules/Capency/components/component.capdropdown.tpl',
    transclude: true,
    require: {
      formCtrl: '?^^capForm'
    },
    controller: 'capValidatePhoneCtrl'
  });
