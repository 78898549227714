export default angular
  .module('directive.store.map', [])

  .directive('storeMap', function () {
    return {
      restrict: 'E',
      bindToController: {
        device: '<',
        modalCtrl: '<?',
        daysModel: '@days',
        strOneTime: '@oneTime',
        strTwoTimes: '@twoTimes',
        strClosed: '@closed'
      },
      controllerAs: 'storeCtrl',
      /* @ngInject */
      controller: function ($scope, $injector) {
        const ctrl = this;

        ctrl.$onInit = async () => {
          const response = await import(/* webpackChunkName: "directive.store.map" */ 'Modules/Stores/import/directive.store.map.init.js');
          $injector.loadNewModules([response.default.name]);
          const service = $injector.get('InitStoreMap');
          service.init(ctrl, $scope);
        };
      }
    };
  });
