import 'import/js/directives/collapsemenu/directive.collapse.menu.less';

export default angular
  .module('directive.collapse.menu', [])

  /* @ngInject */
  .directive('collapseMenu', function ($document) {
    return {
      restrict: 'A',
      scope: true,
      link: (scope, element) => {
        scope.isOpen = false;

        function onBodyClick(event) {
          if (scope.isOpen && !element[0].contains(event.target)) {
            scope.$apply(() => {
              scope.isOpen = false;
            });
            $document.off('click', onBodyClick);
          }
        }

        scope.clickMenu = event => {
          if (scope.device.isTouch) {
            if (!scope.isOpen) {
              event.preventDefault();
              event.stopPropagation();
              element.find('*[lazy-container]').scroll();
              $document.on('click', onBodyClick);
            }
            scope.isOpen = !scope.isOpen;
          }
        };

        scope.showMenu = () => {
          if (scope.device.isTouch) return;
          scope.isOpen = true;
          element.find('*[lazy-container]').scroll();
        };

        scope.hideMenu = () => {
          scope.isOpen = false;
        };

        scope.$on('$destroy', () => {
          $document.off('click', onBodyClick);
        });
      }
    };
  });
