export default angular
  .module('filter.truncate.date', [])

  /* @ngInject */
  .filter('truncateDate', function () {
    return value => {
      if (angular.isString(value) && value.indexOf('h00') === value.length - 3) {
        return value.slice(0, -2);
      }
      return value;
    };
  });
