import 'import/js/plugins/jquery.equalheights.js';

export default angular
  .module('directive.tab.equalheights', [])

  /* @ngInject */
  .directive('tabEqualHeights', function ($timeout) {
    return {
      link: (scope, element) => {
        $timeout(() => {
          const $tabs = element.children('.tab-pane');
          $tabs.show().children('.content').equalHeights().end().removeAttr('style');
        });
      }
    };
  });
