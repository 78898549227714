export default angular
  .module('filter.phone', [])

  .filter('phoneNumber', function () {
    return num => {
      if (num === null || num === undefined) return num;
      if (num.includes('.')) return num.replace(/\./g, ' ');
      return num.replace(/(\d{2}(?=[^\d]*\d))/g, '$1 ');
    };
  });
