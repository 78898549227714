export default angular
  .module('search', [])

  .directive('searchResultContent', function () {
    return {
      restrict: 'E',
      scope: true,
      bindToController: {
        search: '@',
        contents: '<',
        device: '<'
      },
      controllerAs: 'searchCtrl',
      /* @ngInject */
      controller: function ($element, $timeout, $injector, ProductsGridService) {
        const ctrl = this;

        ctrl.$onInit = async () => {
          if (!ctrl.contents) {
            const response = await import(/* webpackChunkName: "search.init" */ 'Modules/Search/import/search.init.js');
            $injector.loadNewModules([response.default.name]);
            const service = $injector.get('ImportSearch');
            service.init(ctrl, $element);
            return;
          }

          ctrl.items = ctrl.contents.Products;
          ctrl.count = ctrl.contents.Count;
          if (ctrl.count > 0) {
            $element.removeClass('hidden');
          }
        };

        ctrl.loaded = () => {
          $timeout(() => {
            ProductsGridService.resizeGrid('searchResultContent', ctrl.device.size, true);
          });
        };
      }
    };
  });
