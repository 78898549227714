/**
 * @class angular.capadresse.capDropdown
 * @memberOf angular.capadresse
 * @desc Classe helper: fournit une classe de base et des méthodes abstraites pour la gestion de dropdowns sur des champs de formulaires.
 */
export class CapDropdown {
  constructor($scope, $element, $transclude, $timeout) {
    const DEBOUNCE = 300;

    this.opened = false;
    this.targeted = false;
    this.options = [];
    this.scope = $scope;
    this.timeout = $timeout;

    this.selection = undefined;
    this.selectedIndex = undefined;

    this.cleared = false;

    // We manually trigger transclusion to ensure content availability in the dom
    $transclude(clone => {
      $element.find('div').append(clone);
    });

    const input = $element[0].querySelectorAll('[ng-model]')[0];

    if (input === undefined) {
      throw new Error('Component should encapsulate an input element with ngModel');
    }

    this.input = input;
    angular.element(input).attr('autocomplete', 'none');
    angular.element(input).on('blur', () => {
      if (this.targeted) {
        return;
      }

      this.timeout(() => {
        this.scope.$apply(() => {
          this.opened = false;
        });
      });
    });

    this.modelCtrl = angular.element(input).controller('ngModel');
    this.modelCtrl.$overrideModelOptions({ debounce: DEBOUNCE });

    this.modelCtrl.$viewChangeListeners.push(this.getOptions.bind(this));
    this.listenerIndex = this.modelCtrl.$viewChangeListeners.length - 1;
  }

  clear() {
    const cleared = !this.selection && this.modelCtrl.$viewValue === '';

    if (cleared) {
      return;
    }

    this.cleared = true;

    delete this.selection;
    delete this.selectedIndex;
    this.modelCtrl.$setViewValue('');
    this.modelCtrl.$render();
  }

  /**
   * Close the dropdown
   */
  close() {
    if (this.opened) {
      this.opened = false;
    }
  }

  /**
   * Completely disable component behaviour
   */
  disable() {
    this.modelCtrl.$viewChangeListeners.splice(this.listenerIndex, 1);
    this.disabled = true;
  }

  /**
   * Finds in select's options list, the index of given value
   * @param {Object} value
   */
  findIndex(value) {
    return this.options.findIndex(option => {
      // All capadress responses should have inputOutput, which acts as and almost uniq key
      if ('inputOutput' in option) {
        return option.inputOutput === value.inputOutput;
      }

      // Fallback
      return this.parseOption(option) === this.parseOption(value);
    });
  }

  /**
   * Track user's focus to highlight corresponding option in the dropdown
   * @param {number} index
   */
  focus(index) {
    this.selectedIndex = index;
  }

  /**
   * Triggered when user types, fetches the list of options to display in the dropdown
   */
  async getOptions() {
    const viewValue = this.modelCtrl.$viewValue;

    const afterSelection = this.parseOption(this.selection) === viewValue;
    const afterClear = !this.selection && viewValue === '';

    if (afterSelection || afterClear) {
      return;
    }

    const options = await this.fetchList(viewValue);

    this.timeout(() => {
      this.scope.$apply(() => {
        if (options !== undefined) {
          this.options = options;
        }

        if (this.options.length > 0) {
          this.open();
        }
      });
    });
  }

  isSelected(index) {
    return this.selectedIndex === index;
  }

  /**
   * Apply user selection
   * @param {Object} value
   * @param {number} index index of the value inside the list of options
   */
  select(value, index) {
    this.selection = value;
    this.selectedIndex = index !== undefined ? index : this.findIndex(value);
    this.cleared = false;

    const viewValue = this.parseOption(value);
    this.modelCtrl.$setViewValue(viewValue);
    this.modelCtrl.$render();
  }

  onArrowKey(event) {
    if (!this.opened) {
      return;
    }

    event.preventDefault();
    const up = event.keyCode === 38;
    const limit = up ? 0 : this.options.length - 1;

    if (this.selectedIndex === limit) {
      return;
    }

    const nextIndex = up ? this.selectedIndex - 1 : this.selectedIndex + 1;
    this.focus(nextIndex);
  }

  onSelection(value, index) {
    this.select(value, index);

    this.opened = false;
  }

  onEnter() {
    this.targeted = true;
  }

  onKeyDown(event) {
    if (this.disabled) {
      return;
    }

    switch (event.keyCode) {
      case 9: // Tab
      case 13: // Enter
        const selection = this.options[this.selectedIndex];

        if (selection === undefined) {
          return;
        }

        event.preventDefault();
        this.select(selection, this.selectedIndex);
        this.close();

        break;
      case 38: // Arrow Up
      case 40: // Arrow Down
        this.onArrowKey(event);
        break;
      default:
        return;
    }
  }

  /**
   * Used to keep track of user focus over the input and dropdown
   * Allows to close the dropdown on outside click only
   */
  onLeave() {
    this.targeted = false;
  }

  /**
   * Prodramatically opens the dropdown
   */
  open() {
    if (this.options.length && !this.selection) {
      this.focus(0);
    }

    this.opened = true;
  }

  // Abstract Methods

  /**
   * Function to override, set what should be displayed from the options
   * @param {Object} option often a capadresse return option
   * @returns {string} value to be displayed in the dropdown list
   */
  displayOption(value) {
    return value;
  }

  /**
   * Function to override, is used to fetch propositions from capaddresse
   * @param {string} value search term from user input
   * @returns {Promise<string[]>} propositions from capadresse
   */
  async fetchList(value) {
    return [];
  }

  /**
   * Fuction to override, parses the selected option to fill in the ngModel value
   * @param {Object} option often a capadresse return option
   * @returns {string} ngModel value
   */
  parseOption(value) {
    return value;
  }
}
