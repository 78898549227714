export default angular
  .module('directive.truncate', [])

  /* @ngInject */
  .directive('truncate', function ($injector) {
    return {
      scope: {
        maxHeight: '<',
        ellipsis: '@'
      },
      restrict: 'A',
      link: async (scope, element, attrs) => {
        if (window.isBot || attrs.truncate !== 'true') return;
        const response = await import(/* webpackChunkName: "truncate.init" */ 'import/js/directives/truncate/directive.truncate.init.js');
        $injector.loadNewModules([response.default.name]);
        const service = $injector.get('Truncate');
        service.init(element, scope);
      }
    };
  });
