import { remove } from 'lodash-es';
import uibRating from 'angular-ui-bootstrap/src/rating';

export default angular
  .module('jobs.form', [uibRating])

  /* @ngInject */
  .controller('JobsController', function ($scope, $injector) {
    const ctrl = this;

    ctrl.formData = {
      Attachments: [],
      errors: [],
      Object: 'Candidature'
    };

    ctrl.dzOptions = {
      paramName: 'files',
      acceptedFiles: 'image/jpeg, image/jpg, image/png, image/gif, .pdf, .doc, .docx, .xls, .xlsx, .zip',
      uploadMultiple: true,
      renameFilename(name) {
        return `${new Date().getTime()}_!_${name}`;
      }
    };

    ctrl.dzCallbacks = {
      successmultiple(files) {
        files.forEach(file => {
          ctrl.formData.Attachments.push({
            Name: file.name,
            ServerName: file.serverName
          });
        });
      },
      removedfile(file) {
        remove(ctrl.formData.Attachments, { Name: file.name });
      }
    };

    ctrl.init = targetCtrl => {
      ctrl.infos = {
        designation: targetCtrl.designation,
        ref: targetCtrl.ref,
        date: targetCtrl.date,
        ville: targetCtrl.ville,
        department: targetCtrl.department
      };
    };

    onInit();
    async function onInit() {
      const response = await import(/* webpackChunkName: "jobs.form" */ 'Modules/Form/import/jobs.form.init.js');
      $injector.loadNewModules([response.default.name]);
      const service = $injector.get('ImportJobsForm');
      service.init(ctrl, $scope);
    }
  });
