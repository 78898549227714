export default angular
  .module('service.authentication', [])

  /* @ngInject */
  .factory('LogService', function ($templateCache, $cacheFactory, AccountService, HttpService, Routes) {
    let redirect, email;

    /**
     * Méthode permettant d'obtenir l'URL de la route spécifiée
     * en paramètre, en fonction du contexte de localisation
     * @param {string} route - Le nom de la route dont on veut récupérer l'URL
     */
    const defineUrl = function (route) {
      const url = Routes.filter(r => r.Name === route)[0];
      return url[_LNG_] ? url[_LNG_].URL : url.en.URL;
    };

    return {
      login: (data, routeName = 'Login') =>
        HttpService.post({
          url: `/${defineUrl(routeName)}`,
          data
        })
          .then(response => {
            $cacheFactory.get('$http').removeAll();
            return response;
          })
          .catch(error => {
            console.error(error);
          }),
      logout: () =>
        HttpService.post({
          url: '/Logout',
          data: {}
        })
          .then(response => {
            $cacheFactory.get('$http').removeAll();
            $templateCache.remove(AccountService.getLinks().AccountUpdate);
            $templateCache.remove(AccountService.getLinks().AccountHome);
            return response;
          })
          .catch(error => {
            console.error(error);
          }),
      redirect,
      email
    };
  });
