import 'Modules/Product/import/component.product-bottom-tabs.tpl';

export default angular
  .module('component.product-bottom-tabs', [])
  /**
   * @class specifics.Product.productBottomTabs
   * @memberOf specifics.Product
   * @desc This component displays a tab header for each section provided to the productBottom component
   *  allowing to navigate through them
   * @param {Array<Section>} sections list of actually provided sections.
   * @param {string} current the current slot on top of which the tab header is set
   * @example
   * <product-bottom-tabs sections="$ctrl.sections" current="$ctrl.slot"></product-bottom>
   */
  .component('productBottomTabs', {
    templateUrl: 'Modules/Product/import/component.product-bottom-tabs.tpl',
    bindings: {
      sections: '<',
      current: '<'
    },
    controllerAs: 'ctrl',
    controller: function () {
      const ctrl = this;

      ctrl.anchor = slot => `bottom-${slot}`;
    }
  });
