export default angular
  .module('service.availability', [])

  /* @ngInject */
  .factory('AvailabilityService', function ($filter, AppService, DateService) {
    const service = {
      formatMessage
    };
    return service;

    ////////////
    /**
     * Cette fonction récupère un message en fonction des paramètres passés.
     * @param {Object} params - Les paramètres de la fonction.
     * @param {string} params.message - Le message à traiter.
     * @param {string} params.date - La date à utiliser dans le message.
     * @param {boolean} params.isPickUp - Un indicateur pour déterminer si c'est une récupération (PickUp) ou une livraison (Delivery).
     * @returns {Promise<string>} - Le message formaté ou une chaîne de caractères inchangée.
     * @throws {Error} - Lance une erreur si la récupération du message échoue.
     */
    async function getMessage(params) {
      // Validation des entrées
      if (!angular.isString(params.message) || !angular.isString(params.date) || typeof params.isPickUp !== 'boolean') {
        console.warn('Les paramètres sont invalides.');
        return '';
      }

      try {
        if (params.message.includes('{jours}')) {
          // Si la date n'est pas spécifiée (valeur par défaut), renvoie un message d'indisponibilité approprié
          if (params.date === '0001-01-01T00:00:00') {
            const translate = await AppService.getTranslate();
            return params.isPickUp ? translate.messages.UnavailablePickUp : translate.messages.UnavailableDelivery;
          }

          // Calcule le nombre de jours entre la date passée et la date actuelle
          const daysDifference = Math.ceil((DateService.stringToDate(params.date) - new Date()) / (1000 * 60 * 60 * 24));

          // Si la livraison est prévue pour aujourd'hui, obtient le message "ShippingToday"
          if (daysDifference === 0) {
            const translate = await AppService.getTranslate();
            return translate.messages.ShippingToday;
          }

          // Remplace "{jours}" par le nombre de jours dans le message
          return params.message.replace('{jours}', daysDifference);
        }

        // Si le message contient les marqueurs "MMMM" ou "mm", formate la date en conséquence
        if (params.message.includes('MMMM') || params.message.includes('mm')) {
          params.message = params.message.endsWith("'") ? params.message.slice(0, -1) : params.message;
          params.message = $filter('date')(DateService.stringToDate(params.date), `'${params.message}'`);
          params.message = $filter('truncateDate')(params.message);
        }

        return params.message;
      } catch (error) {
        console.warn('Une erreur s\'est produite lors de la récupération du message.');
        return '';
      }
    }

    /**
     * Cette fonction formate un message et met à jour des propriétés d'un objet.
     * @param {Object} obj - L'objet contenant les messages et dates à formater.
     * @param {string} key - La clé indiquant le type de message à formater ('PickUp' ou 'Delivery').
     * @throws {Error} - Lance une erreur si la récupération du message échoue.
     */
    async function formatMessage(obj, key) {
      // Validation des entrées
      if (!angular.isObject(obj) || (key !== 'PickUp' && key !== 'Delivery')) {
        console.warn('Les paramètres sont invalides.');
        return '';
      }

      try {
        obj[`${key}Message`] = await getMessage({
          message: obj[`${key}Message`],
          date: obj[`${key}Date`],
          isPickUp: key === 'PickUp'
        });

        // Détermine la classe CSS à utiliser en fonction de la disponibilité (ok ou out)
        obj[`${key}MessageClass`] = obj[key] ? 'ok' : 'out';
      } catch (error) {
        console.warn('Une erreur s\'est produite lors de la récupération du message.');
        return '';
      }
    }
  });
