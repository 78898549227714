import 'Modules/Capency/components/component.capdropdown.tpl';
import 'Modules/Capency/components/component.capdropdown.less';
import { CapDropdown } from '../helpers/capdropdown';

/**
 * @class angular.capadresse.capSearchLocality
 * @desc Composant angularJS : affiche une liste de propositions de départements sous un champ de saisie
 * @memberOf angular.capadresse
 */
/* @ngInject */
class CapSearchLocality extends CapDropdown {
  constructor($scope, $element, $transclude, $timeout, capencyService) {
    super($scope, $element, $transclude, $timeout);
    this.capService = capencyService;
  }

  $onInit() {
    if (!this.formCtrl) {
      this.disable();
      return;
    }

    this.formCtrl.capSearchLocality = {
      setOption: this.setOption.bind(this),
      getOption: () => this.selection,
      clear: this.clear.bind(this)
    };

    this.initField();

    this.linkFields();
  }

  /**
   * When form is already filled, link with capAdress Values
   */
  initField() {
    if (!this.modelCtrl.$modelValue) {
      return;
    }

    this.selection = { postalCode: this.modelCtrl.$modelValue };
  }

  /**
   * Initialize relation with other cap fields
   * eg : empty city when locality changes
   */
  linkFields() {
    const linkedFields = ['capSearchCity', 'capSearchStreet', 'capPlace', 'capBuilding'];

    this.modelCtrl.$viewChangeListeners.unshift(() => {
      const afterSelection = this.modelCtrl.$viewValue === this.parseOption(this.selection);

      if (afterSelection || this.cleared) {
        return;
      }

      linkedFields.forEach(field => {
        if (field in this.formCtrl) {
          this.formCtrl[field].clear();
        }
      });
    });
  }

  /**
   * Fetch list of localities from Capaddress
   * @param {string} userInput
   */
  async fetchList(userInput) {
    const country = this.formCtrl.capCountry.$modelValue;
    const countryId = angular.isObject(country) ? country.IDCountry : country;

    return this.capService.fetchLocalities(userInput, countryId);
  }

  /**
   * What to display from the option on the dropdown
   * @param {CapAddressOption} option
   */
  displayOption(option) {
    return option.inputOutput;
  }

  /**
   * Parse the value to save in the ngModel from the capAdress result
   * @param {CapAddressOption} option
   */
  parseOption(option) {
    if (option === undefined) {
      return;
    }

    return option.postalCode;
  }

  /**
   * Interface to other cap fields that can update this one's value
   * @param {CapAddressOption} option
   * @param {number} index optionnal: index of the option in options list
   */
  setOption(option, index) {
    super.select(option, index);
  }

  /**
   * Triggered on user selection
   * @param {CapAddressOption} option
   * @param {number} index
   */
  select(option, index) {
    this.setOption(option, index);

    if (this.formCtrl.capSearchCity) {
      this.formCtrl.capSearchCity.setOption(option);
    }

    if ('capSearchStreet' in this.formCtrl) {
      this.formCtrl.capSearchStreet.focus();
    }
  }
}

export default angular
  .module('component.capSearchLocality', [])
  .controller('capSearchLocalityCtrl', CapSearchLocality)
  .component('capSearchLocality', {
    templateUrl: 'Modules/Capency/components/component.capdropdown.tpl',
    transclude: true,
    require: {
      formCtrl: '?^^capForm'
    },
    controller: 'capSearchLocalityCtrl'
  });
