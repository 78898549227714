export default angular
  .module('filter.format', [])

  /* @ngInject */
  .filter('format', function () {
    return (value, ...args) => {
      if (!value) return value;

      args.forEach((arg, i) => {
        const reg = new RegExp(`\\{${i}\\}`, 'g');
        value = value.replace(reg, arg);
      });

      return value;
    };
  });
