import 'import/js/components/addtocart/component.addtocart.modulo.js';
import 'import/js/components/addtocart/component.addtocart.quantity.js';
import 'import/js/components/addtocart/component.addtocart.classic.js';
import 'import/js/components/addtocart/component.addtocart.mini.js';
import 'import/js/components/addtocart/component.addtocart.js';
import 'import/js/components/addtowishlist/component.addtowishlist.js';
import 'import/js/components/addtoquotation/component.addtoquotation.advanced.js';
import 'import/js/components/input.quantity/component.input.quantity.js';
import 'import/js/components/notlogged/component.notlogged.js';
import 'import/js/directives/collapsemenu/directive.collapse.menu.js';
import 'import/js/directives/rzslider/directive.rzslider.js';
import 'import/js/directives/dropzone/directive.dropzone.js';
import 'import/js/directives/swiper/directive.swiper.js';

export default angular.module('components', [
  'component.addtocart.modulo',
  'component.addtocart.quantity',
  'component.addtocart.classic',
  'component.addtocart.mini',
  'component.addtocart',
  'component.addtowishlist',
  'component.addtoquotation.advanced',
  'component.input.quantity',
  'component.notlogged',
  'directive.collapse.menu',
  'directive.rzslider',
  'directive.dropzone',
  'directive.swiper'
]);
