export default angular
  .module('service.phone', ['service.capency'])

  /* @ngInject */
  .factory('PhoneService', function (AppService, HttpService, capencyService) {

    const standardRegexPhone = /^(?:\+\d{1,3}\s?)?(?:\d{1,3}|\d{1,4})\s?\d{1,4}\s?\d{1,9}$/;

    const regexMobilePhone = {
      'FR': /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/, // France métropolitaine
      'RE': /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // 187 => La Réunion,
      'YT': /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // 147 => Mayotte
      'PM': /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // 197 => Saint - Pierre - et - Miquelon
      'GP': /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // 89 => Guadeloupe, Saint-Martin et Saint-Barthélemy
      'GF': /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // 97 => French Guiana(Guyane)
      'MQ': /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // 144 => Martinique
      'WF': /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // 242 => Wallis-et-Futuna
      'NC': /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // 166 => Nouvelle - Calédonie
      'PF': /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // 183 => French Polynesia
      'BE': /^((\+|00)32\s?|0)(\d\s?\d{3}|\d{2}\s?\d{2})(\s?\d{2}){2}$/, // 27 => Belgique
      'DE': /^(\d{5}([-\/]|)\d{0,9}$|((00\d{5,6}|\+\d{4,6}|\+[(]\d{1,3}[)]))\d{1,4}(|[-\/])\d{1,7}$)/, // 8 => Allemagne
      'IT': /^(\+39|0039|0){1}(38[890]|34[7-90]|36[680]|33[3-90]|32[89])\d{7}$/, // 114 => Italie
      'ES': /^(\+34|0034|0){1}(6(([0-9]{8})|([0-9]{2} [0-9]{6})|([0-9]{2} [0-9]{3} [0-9]{3}))|9(([0-9]{8})|([0-9]{2} [0-9]{6})|([1-9] [0-9]{7})|([0-9]{2} [0-9]{3} [0-9]{3})|([0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2})))$/, // 72 => Espagne
      'CH': /^(\+41|0041|0){1}(\(0\))?[0-9]{9}$/, // 215 => Suisse
      'GB': /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/ // 189 => Royaume-Uni
    };

    const regexLandline = {
      'BE': /^(?:\+324|00324|04)( ?\d){8}$$/, // 27 => Belgique
      'DE': /^((01[0-9])\d{9}$|(1[0-9])\d{9}$|((00\d{5,6}|\+\d{4,6}|\+[(]\d{1,3}[)]\d{3}))(|[-\/])\d{8}$)/, // 8 => Allemagne
      'IT': /^(\+39|0039|0){1}((38[{8,9}|0])|(34[{7-9}|0])|(36[6|8|0])|(33[{3-9}|0])|(32[{8,9}]))([\d]{7})$/, // 114 => Italie
      'ES': /^(\+34|0034|0){1}(6\d{2}|7[1-9]\d{1})\d{6}$/, // 72 => Espagne
      'CH': /^(\b(0041|0)|\B\+41)(\s?\(0\))?(\s)?[1-9]{2}(\s)?[0-9]{3}(\s)?[0-9]{2}(\s)?[0-9]{2}\b$/, // 215 => Suisse
      'GB': /^((\+44|0044)\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/ // 189 => Royaume-Uni
    };

    let countries;
    let useLibphonenumber;
    let useCapPhone;
    let parsePhoneNumberFromString;
    let isSupportedCountry;

    const isValidPhone = async function (phoneNumber, idCountry, isMobile = true) {
      if (!phoneNumber || (!angular.isString(phoneNumber) && !angular.isNumber(phoneNumber))) return true;

      // Get the country code from Id
      const countryCode = await getCountryCode(idCountry);

      // Check how to validate input phone number
      if (useLibphonenumber === undefined) {
        const phoneTypeValidation = await AppService.getAllParams('PhoneVerificationType');
        useLibphonenumber = phoneTypeValidation === 'Libphonenumber';
        useCapPhone = phoneTypeValidation === 'CapPhone';
      }

      // Si le paramètre PhoneVerificationType n'est pas de type "Libphonenumber"
      // alors on utilise les regex Octave sinon on utilise la librairie libphonenumber-js pour valider le numéro
      if (useCapPhone) {
        return isValidPhoneWithCapPhone(phoneNumber, idCountry, isMobile);
      } else if (useLibphonenumber) {
        return isValidPhoneWithLibPhoneNumber(phoneNumber, countryCode, isMobile);
      }
      if (typeof isMobile !== 'boolean') return true;
      return isMobile ? isValidMobilePhone(phoneNumber, countryCode) : isValidLandline(phoneNumber, countryCode);
    };

    const getCountryCode = async function (IDZoneCountry) {
      if (!angular.isNumber(IDZoneCountry)) {
        IDZoneCountry = parseInt(IDZoneCountry);
        if (!angular.isNumber(IDZoneCountry)) {
          return null;
        }
      }

      // On ne charge la liste des pays que la première fois
      if (!countries) {
        countries = await HttpService.get({
          url: '/countries',
          cache: true
        });
      }
      const country = countries.find(function (c) {
        return c.IDCountry === IDZoneCountry;
      });

      if (!country || !country.hasOwnProperty('CountryCode')) {
        return null;
      }

      return country.CountryCode;
    };

    const loadLibphonenumberModules = async function () {
      if (!parsePhoneNumberFromString || !isSupportedCountry) {
        const libphonenumber = await import('libphonenumber-js/max');
        parsePhoneNumberFromString = libphonenumber.parsePhoneNumberFromString;
        isSupportedCountry = libphonenumber.isSupportedCountry;
      }
    };

    const isValidPhoneWithLibPhoneNumber = async function (phoneNumber, countryCode, isMobile) {
      await loadLibphonenumberModules();

      // Si la librairie libphonenumber-js ne prend pas en charge un code pays, alors on utilise les regex Octave
      if (!isSupportedCountry(countryCode)) {
        return isMobile ? isValidMobilePhone(phoneNumber, countryCode) : isValidLandline(phoneNumber, countryCode);
      }

      const phoneObj = parsePhoneNumberFromString(phoneNumber, countryCode, { extract: false });
      if (phoneObj && phoneObj.isPossible() === true && phoneObj.isValid() === true) {
        const type = phoneObj.getType();
        if (isMobile) {
          return type === 'MOBILE' || type === 'FIXED_LINE_OR_MOBILE';
        }
        return type === 'FIXED_LINE' || type === 'FIXED_LINE_OR_MOBILE';
      }

      return false;
    };

    const isValidPhoneWithCapPhone = async function (phoneNumber, idCountry, isMobile) {
      const result = await capencyService.validatePhone(phoneNumber, idCountry, isMobile);
      return (result !== null && result.isValid === true);
    };

    const isValidMobilePhone = function (phoneNumber, countryCode) {
      const regexPhone = (countryCode in regexMobilePhone) ? regexMobilePhone[countryCode] : standardRegexPhone;
      return regexPhone.test(phoneNumber);
    };

    const isValidLandline = function (phoneNumber, countryCode) {
      const regexPhone = (countryCode in regexLandline) ? regexLandline[countryCode] : standardRegexPhone;
      return regexPhone.test(phoneNumber);
    };

    return {
      isValidPhone,
      getCountryCode
    };
  });
