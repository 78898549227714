export default angular
  .module('directive.dropzone', [])

  /* @ngInject */
  .directive('ngDropzone', function ($injector) {
    return {
      restrict: 'A',
      template: '<div></div>',
      replace: true,
      scope: {
        options: '=?', //http://www.dropzonejs.com/#configuration-options
        callbacks: '=?', //http://www.dropzonejs.com/#events
        init: '=?',
        target: '=?',
        methods: '=?', //http://www.dropzonejs.com/#dropzone-methods
        acceptedFiles: '@?',
        success: '&?',
        removedfile: '&?'
      },
      link: async (scope, element) => {
        const response = await import(/* webpackChunkName: "dropzone" */ 'import/js/directives/dropzone/dropzone.js');
        $injector.loadNewModules([response.default.name]);
        const service = $injector.get('ImportDropzone');
        service.init(scope, element);
      }
    };
  });
