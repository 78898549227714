export default angular
  .module('directive.swiper', [])

  /* @ngInject */
  .directive('swiper', function ($injector) {
    return {
      restrict: 'A',
      link: async (scope, element, attrs) => {
        const response = await import(/* webpackChunkName: "swiper" */ 'import/js/directives/swiper/swiper.js');
        $injector.loadNewModules([response.default.name]);
        const service = $injector.get('SwiperService');
        service.init(scope, element, attrs);
      }
    };
  });
