import './components/component.cap-search-locality.js';
import './components/component.cap-search-city.js';
import './components/component.cap-search-street.js';
import './components/component.cap-validate-email.js';
import './components/component.cap-validate-phone.js';

import './directives/cap-form.js';
import './directives/cap-country.js';
import './directives/cap-trigger.js';
import './directives/cap-place.js';
import './directives/cap-building.js';
import './directives/cap-warning-mobile.js';
import './directives/cap-warning-phone.js';

import './services/service.capency.js';

/**
 * @class angular.capadresse
 * @memberOf angular
 * @description
 * Ce module met à disposition éléments nécessaires à l'utilisation du service Capaddress pour l'aide à la completion d'adresses.
 */
angular.module('capency', [
  'component.capSearchLocality',
  'component.capSearchCity',
  'component.capSearchStreet',
  'component.capValidatePhone',
  'component.capValidateEmail',
  'directive.capForm',
  'directive.capCountry',
  'directive.capTrigger',
  'directive.capPlace',
  'directive.capBuilding',
  'directive.capWarningMobile',
  'directive.capWarningPhone',
  'service.capency'
]);
