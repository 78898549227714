import 'Modules/Finder/import/service.js';
import 'Modules/Finder/import/autocomplete.finder.less';

export default angular
  .module('directive.autocomplete.finder', ['finder.service'])

  .directive('autoCompleteFinder', function () {
    return {
      restrict: 'E',
      scope: true,
      controllerAs: 'searchCtrl',
      /* @ngInject */
      controller: function ($rootScope, $element, $location, $timeout, RoutesService, FinderService) {
        const ctrl = this;

        ctrl.inputChangeHandler = () => {
          $rootScope.$broadcast('searchUpdate');
        };

        ctrl.goSearch = (event, fromBtn) => {
          if (fromBtn) {
            event.stopPropagation();
          } else {
            if (event.which === 13) {
              $(event.target).blur();
            } else {
              return;
            }
          }
          $timeout(goSearch, 600);
        };

        ctrl.$onInit = () => {
          FinderService.setCtrl(ctrl);
        };

        function goSearch() {
          if (!ctrl.searchStr || ctrl.searchStr === '') return;
          const url = `${RoutesService.get().Search.route}/${encodeURIComponent(ctrl.searchStr).replace(new RegExp('%20', 'g'), '+').replace(new RegExp('%2F', 'g'), '%252F')}`;
          $location.path(url);
          $rootScope.$broadcast('searchClose');
        }
      }
    };
  });
