export default angular
  .module('directive.rzslider', [])

  /* @ngInject */
  .directive('rzslider', function ($compile, $injector) {
    return {
      restrict: 'E',
      scope: {
        rzSliderModel: '=?',
        rzSliderHigh: '=?',
        rzSliderOptions: '&?'
      },
      template: '',
      link: async (scope, element) => {
        loadTemplate();

        async function loadTemplate() {
          const response = await import(/* webpackChunkName: "rzslider" */ 'import/js/directives/rzslider/rzslider.html');
          element.html(response.default);
          $compile(element.contents())(scope);
          init();
        }

        async function init() {
          const response = await import(/* webpackChunkName: "rzslider.init" */ 'import/js/directives/rzslider/rzslider.init.js');
          $injector.loadNewModules([response.default.name]);
          const RzSlider = $injector.get('RzSlider');
          scope.slider = new RzSlider(scope, element.children());
        }
      }
    };
  });
