export default angular
  .module('directive.scrolltop', [])

  .directive('backToTop', function () {
    return {
      restrict: 'A',
      controllerAs: 'backToTopCtrl',
      /* @ngInject */
      controller: function ($scope, $window, WindowEventsService) {
        const ctrl = this;
        ctrl.active = false;

        ctrl.$onDestroy = () => {
          WindowEventsService.listen(false, 'resize', handler);
        };

        WindowEventsService.listen(true, 'scroll', handler, 500);

        function handler() {
          const sc = isNaN(window.pageYOffset) ? document.documentElement.scrollTop : window.pageYOffset;
          $scope.$apply(() => {
            ctrl.active = sc > $window.innerHeight ? true : false;
          });
        }
      }
    };
  });
