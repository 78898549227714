/*
 * Modal directive from <https://github.com/britztopher/genric-modal/blob/master/directives/directives.js>.
 **/
import modal from 'angular-ui-bootstrap/src/modal';

export default angular
  .module('module.modal', [modal])

  /* @ngInject */
  .factory('ModalService', function ($uibModal) {
    let modalInstance = [];
    return {
      show: (template, data, size, ctrl, ctrlAs, device, options, onClosed, onOpened) => {
        $(document.body).prepend('<div id="tmpBackdrop" class="modal-backdrop in" />');
        const _modal = $uibModal.open({
          size,
          templateUrl: template,
          controller: ctrl || 'modalCtrl',
          controllerAs: ctrlAs || 'modalCtrl',
          bindToController: true,
          resolve: {
            data: () => data,
            device: () => device,
            options: () => options
          },
          windowClass: (data && data.windowClass) || null,
          backdrop: (data && data.backdrop) || true,
          keyboard: data && angular.isDefined(data.keyboard) ? data.keyboard : true
        });
        _modal.result.catch(error => {
          if (error !== 'close' && error !== 'cancel' && error !== 'backdrop click' && error !== 'escape key press') {
            console.error(error);
          }
        });
        _modal.id = new Date().getTime();
        _modal.closed
          .then(() => {
            modalInstance = modalInstance.filter(o => o.id !== _modal.id);
            if (onClosed) {
              onClosed(_modal.result.$$state.value);
            }
          })
          .catch(error => {
            console.error(error);
          });
        _modal.opened
          .then(() => {
            $('#tmpBackdrop').remove();
            if (onOpened) {
              onOpened();
            }
          })
          .catch(error => {
            console.error(error);
          });
        modalInstance.push(_modal);
        return _modal;
      },
      close: () => {
        modalInstance.forEach(_modal => {
          _modal.close();
        });
        modalInstance = [];
      },
      closeCurrent: function () {
        modalInstance[modalInstance.length - 1].close();
      }
    };
  })

  /* @ngInject */
  .directive('openModal', function (ModalService) {
    return {
      restrict: 'A',
      transclude: true,
      replace: true,
      template: '<a class="{{class}}" ng-click="open()" ng-transclude>{{name}}</a>',
      scope: {
        openModal: '@',
        module: '@',
        class: '@',
        ctrl: '@?',
        data: '<?',
        device: '<?',
        options: '<?',
        onClosed: '&',
        size: '@?'
      },
      link: scope => {
        scope.open = () => {
          ModalService.close();
          ModalService.show(
            `/Template/${scope.module}/${scope.openModal}`,
            scope.data,
            scope.size || null,
            scope.ctrl || 'modalCtrl',
            null,
            scope.device,
            scope.options,
            scope.onClosed || null
          );
        };
      }
    };
  })

  /* @ngInject */
  .directive('openCmsModal', function (ModalService) {
    return {
      restrict: 'A',
      transclude: true,
      replace: true,
      template: '<a class="{{class}}" ng-click="open()" ng-transclude>{{name}}</a>',
      scope: {
        openCmsModal: '@',
        class: '@',
        autoScroll: '@?',
        title: '@?',
        ctrl: '@?',
        size: '@?',
        device: '<?'
      },
      link: scope => {
        scope.open = () => {
          ModalService.close();
          ModalService.show(
            '/Template/Modal/ModalCms',
            {
              url: `/page-cms/${scope.openCmsModal}`
            },
            scope.size || null,
            scope.ctrl || 'modalCtrl',
            null,
            scope.device,
            {
              modalTitle: scope.title || '',
              modalAutoScroll: scope.autoScroll ? true : false
            }
          );
        };
      }
    };
  })

  /* @ngInject */
  .directive('modalAutoScroll', function ($timeout) {
    return {
      restrict: 'A',
      link: (scope, element, attrs) => {
        if (!scope.$eval(attrs.modalAutoScroll)) return;

        resize();
        // $timeout(resize, 3000);

        function resize() {
          $timeout(() => {
            const $modalDialog = element.closest('.modal-dialog');
            const $scrollContainer = element.find('.scroll-container');
            let maxHeight = 0;

            maxHeight += parseInt($modalDialog.css('margin-top')) + parseInt($modalDialog.css('margin-bottom'));
            maxHeight += $modalDialog.find('.modal-header').outerHeight();
            if ($modalDialog.find('.modal-footer').length) {
              maxHeight += $modalDialog.find('.modal-footer').outerHeight();
            }
            maxHeight += parseInt(element.css('padding-top')) + parseInt(element.css('padding-bottom'));

            if ($scrollContainer.position()) {
              maxHeight += $scrollContainer.position().top;
            }
            maxHeight += 2;

            $scrollContainer.css({
              'overflow-x': 'hidden',
              'overflow-y': 'auto',
              'max-height': `calc(100vh - ${maxHeight}px)`
            });
          });
        }
      }
    };
  });
