export default angular
  .module('password.recovery', [])

  .directive('passwordRecovery', function () {
    return {
      restrict: 'E',
      bindToController: {
        token: '@',
        isTokenValid: '<'
      },
      controllerAs: 'passwordRecoveryCtrl',
      /* @ngInject */
      controller: function ($element, $injector) {
        const ctrl = this;

        ctrl.$onInit = async () => {
          const response = await import(/* webpackChunkName: "password.recovery" */ 'Modules/PasswordRecovery/import/service.js');
          $injector.loadNewModules([response.default.name]);
          const service = $injector.get('ImportPassword');
          service.init(ctrl, $element);
        };
      }
    };
  });
