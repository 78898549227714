export default angular
  .module('directive.selectpicker', [])

  /* @ngInject */
  .directive('selectpicker', function ($injector) {
    return {
      restrict: 'A',
      scope: false,
      link: async (scope, element, attrs) => {
        scope.$eval(`${attrs.ngModel}="${element.val()}"`);

        const response = await import(/* webpackChunkName: "selectpicker" */ 'import/js/directives/selectpicker/selectpicker.js');
        $injector.loadNewModules([response.default.name]);
        const service = $injector.get('ImportSelectpicker');
        service.init(scope, element, attrs);
      }
    };
  });
