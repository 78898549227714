export default angular
  .module('directive.price', [])

  .directive('productPrice', function () {
    return {
      restrict: 'E',
      require: {
        productCtrl: '^^productDetail'
      },
      bindToController: {
        jsonPrice: '=',
        prices: '<',
        txtFirstRange: '@',
        txtRange: '@',
        txtLastRange: '@',
        txtConditioning: '@?'
      },
      /* @ngInject */
      controller: function ($injector) {
        const ctrl = this;

        ctrl.$onInit = async () => {
          const response = await import(/* webpackChunkName: "product" */ 'Modules/Product/import/product.js');
          $injector.loadNewModules([response.default.name]);
          const service = $injector.get('ImportProduct');
          service.initPrice(ctrl);
        };
      }
    };
  });
