import format from 'import/js/plugins/format.js';

export default angular
  .module('filter.discount', [])

  /* @ngInject */
  .filter('discount', function (AppService) {
    let params = null;

    function discountFilter(value) {
      if (value === null) return value;

      if (params !== null) {
        return !angular.isFunction(params.then) ? getDiscountFilter(value) : undefined;
      }
      params = AppService.getParams()
        .then(data => {
          params = data;
        })
        .catch(error => {
          console.error(error);
        });
    }

    function getDiscountFilter(value) {
      if (!angular.isFunction(format)) return value;

      const loc = params.Localization;
      const template = loc.DiscountHTMLFormat;
      const render = template
        .replace('[%amount%]', format(loc.DiscountFormat, parseFloat(value)))
        .replace(',', '##DiscountTSep##')
        .replace('.', loc.DiscountDecimalSeparator)
        .replace('##DiscountTSep##', loc.DiscountThousandSeparator);
      return render;
    }

    discountFilter.$stateful = true;
    return discountFilter;
  });
