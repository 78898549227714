/* ng-infinite-scroll - v1.3.0 - 2016-06-30 from https://github.com/sroze/ngInfiniteScroll */
export default angular
  .module('directive.infinitescroll', [])

  .value('THROTTLE_MILLISECONDS', 250)

  /* @ngInject */
  .directive('infiniteScroll', function ($injector) {
    return {
      scope: {
        infiniteScroll: '&',
        infiniteScrollDistance: '=',
        infiniteScrollDisabled: '=',
        infiniteScrollGrandParent: '='
      },
      restrict: 'A',
      link: async (scope, element, attrs) => {
        const response = await import(/* webpackChunkName: "infinitescroll" */ 'import/js/directives/infinitescroll/directive.infinitescroll.init.js');
        $injector.loadNewModules([response.default.name]);
        const service = $injector.get('InfiniteScroll');
        service.init(scope, element, attrs);
      }
    };
  });
