export default angular
  .module('directive.share', [])

  .directive('socialshare', function () {
    return {
      restrict: 'A',
      scope: true,
      bindToController: {
        socialshare: '@',
        socialshareUrl: '@',
        socialshareText: '@?',
        socialshareMedia: '@?',
        socialshareHashtags: '@?',
        socialshareEmail: '@?',
        modalTitle: '@',
        resultMessage: '@?'
      },
      /* @ngInject */
      controller: function ($element, $injector, AppService) {
        const ctrl = this;

        ctrl.$onInit = async () => {
          const response = await import(/* webpackChunkName: "product" */ 'Modules/Product/import/product.js');
          const params = await AppService.getParams();
          $injector.loadNewModules([response.default.name]);
          const service = $injector.get('ImportProduct');
          const appId = params.Localization.FacebookAppID;
          service.initShare(ctrl, $element, appId);
        };
      }
    };
  });
