export default angular
  .module('directive.nicescroll', [])

  .provider('nicescrollConfig', function () {
    let config = {
      autohidemode: false,
      cursordragontouch: true,
      cursorborder: '1px solid #000',
      cursorborderradius: '6px',
      cursorcolor: '#000',
      cursorwidth: '6px',
      enableobserver: false,
      horizrailenabled: false
    };

    return {
      set: value => {
        config = {
          ...config,
          ...value
        };
      },
      $get: () => config
    };
  })

  /* @ngInject */
  .directive('ngNicescroll', function (nicescrollConfig) {
    return {
      restrict: 'A',
      link: (scope, element, attrs) => {
        if ((scope.device && scope.device.mobile) && !attrs.nicescrollMobile) return;

        let firstTime = true;
        let unwatch = [];
        let niceScroll;
        const timeout = angular.isDefined(attrs.niceTimeout) ? parseInt(attrs.niceTimeout) : 0;

        loadPlugin();

        async function loadPlugin() {
          await import(/* webpackChunkName: "jquery.nicescroll" */ 'jquery.nicescroll');

          if (angular.isUndefined(attrs.niceScrollInitEvent)) {
            setTimeout(() => {
              niceScroll = element.niceScroll(attrs.niceOption && scope.$eval(attrs.niceOption) ? scope.$eval(attrs.niceOption) : nicescrollConfig);
            }, timeout);
          }
        }

        scope.$on('$destroy', () => {
          if (niceScroll && angular.isDefined(niceScroll.version)) {
            niceScroll.remove();
          }
          unwatch.forEach(fn => {
            fn();
          });
          unwatch = null;
        });

        if (angular.isDefined(attrs.niceScrollInitEvent) && firstTime) {
          unwatch.push(
            scope.$on(attrs.niceScrollInitEvent, () => {
              firstTime = false;
              setTimeout(() => {
                niceScroll = element.niceScroll(attrs.niceOption && scope.$eval(attrs.niceOption) ? scope.$eval(attrs.niceOption) : nicescrollConfig);
              }, timeout);
            })
          );
        }

        if (angular.isDefined(attrs.niceScrollEvent)) {
          unwatch.push(
            scope.$on(attrs.niceScrollEvent, () => {
              setTimeout(() => {
                element.getNiceScroll()?.resize();
              }, 100);
            })
          );
        }
      }
    };
  });
