export default angular
  .module('directive.wishlist', [])

  .directive('wishlist', function () {
    return {
      restrict: 'E',
      bindToController: {
        lines: '<'
      },
      controllerAs: 'wishlistCtrl',
      /* @ngInject */
      controller: function ($injector) {
        const ctrl = this;

        ctrl.$onInit = async () => {
          const response = await import(/* webpackChunkName: "wishlist" */ 'Modules/WishList/import/whishlist.js');
          $injector.loadNewModules([response.default.name]);
          const service = $injector.get('ImportWishlist');
          service.init(ctrl);
        };
      }
    };
  });
