import 'import/js/plugins/jquery.equalheights.js';

export default angular
  .module('module.products.grid', [])

  /* @ngInject */
  .directive('productsGrid', function (WindowEventsService, ProductsGridService) {
    return {
      restrict: 'A',
      link: linkFunction
    };

    // Fonction de liaison
    function linkFunction(scope, element) {
      // Sélectionne les éléments enfants avec la classe 'row'
      const rowElements = element.children('.row');
      // Si aucun élément 'row' n'est trouvé, quitte la fonction
      if (!rowElements.length) {
        return;
      }

      // Génère un ID temporaire en utilisant l'ID existant ou en créant un nouveau
      const tmpId = element.attr('id') || generateUniqueId();
      element.attr('id', tmpId);

      // Configure les écouteurs d'événements
      setupEventListeners();

      // Nettoie l'écouteur d'événement lorsque la portée (scope) est détruite
      scope.$on('$destroy', () => {
        WindowEventsService.listen(false, 'resize', onResize);
      });

      // Réagit à l'événement 'updateVisitorContext' en appelant la fonction onResize
      scope.$on('updateVisitorContext', onResize);
      // Appelle la fonction onResize une fois au chargement initial de la directive
      onResize();

      // Configure les écouteurs d'événements pour les redimensionnements
      function setupEventListeners() {
        // Active l'écouteur d'événement 'resize' avec un délai de 500 ms
        WindowEventsService.listen(true, 'resize', onResize, 500);
      }

      // Fonction appelée lors d'un redimensionnement
      function onResize() {
        // Utilise setTimeout pour exécuter le redimensionnement de manière asynchrone
        setTimeout(() => {
          ProductsGridService.resizeGrid(tmpId, scope.device.size, false);
        });
      }

      // Génère un ID unique pour l'élément si nécessaire
      function generateUniqueId() {
        return `pg_${ProductsGridService.getId()}`;
      }
    }
  })

  // Service pour gérer le redimensionnement et l'ajustement des hauteurs des éléments dans une grille de produits
  .service('ProductsGridService', function () {
    const MAX_COLUMN_CLASS = 12;

    // Générer et retourner une fonction pour générer des IDs uniques
    this.getId = () => {
      let tmpId = 0;
      return () => tmpId++;
    };

    // Redimensionner la grille des produits en ajustant les hauteurs
    this.resizeGrid = (elemId, size, lazy, sizes) => {
      const $elem = $(`#${elemId}`);
      const $divs = $elem.children('.row').children('.grid:visible');

      // Vérifier s'il y a suffisamment d'éléments pour ajuster
      if ($divs.length < 2) return;

      // Réinitialiser la hauteur initiale si nécessaire
      if (!lazy) {
        resetInitialHeight($divs);
      }

      // Calculer le nombre de colonnes en fonction de la taille de l'écran
      const nb = calculateColumnCount($divs, size, sizes);
      if (nb === 1) return;

      // Ajuster les hauteurs des éléments de la grille
      adjustHeights($divs, nb);
    };

    // Réinitialiser la hauteur initiale des éléments
    function resetInitialHeight($divs) {
      $divs.children().addClass('init').find('.content').children().height('');
    }

    // Calculer le nombre de colonnes en fonction de la taille de l'écran
    function calculateColumnCount($divs, size, sizes) {
      const colClass = $divs.eq(0).attr('class').split(`col-${size}-`)[1];
      if (!colClass) {
        return;
      }

      let nb;
      if (!sizes) {
        nb = MAX_COLUMN_CLASS / colClass.split(' ')[0];
      } else {
        const width = document.body.clientWidth;
        nb = sizes.reduce((acc, o) => (width < o.breakpoint ? o.nb : acc), 0);
      }
      return nb;
    }

    // Ajuster les hauteurs des éléments dans chaque section de la grille
    function adjustHeights($divs, nb) {
      const productItems = $divs.find('.product-item');

      for (let i = 0; i < productItems.length; i += nb) {
        const $itemsSlice = $divs.slice(i, i + nb);
        const targ = $itemsSlice
          .children('.init')
          .removeClass('init')
          .find('.content');
        const comp = $itemsSlice.find('.compare');

        // Ajuster les hauteurs des éléments dans la section actuelle
        const contentChildren = targ.eq(0).children();
        for (let j = 0; j < contentChildren.length; j++) {
          const $child = $(contentChildren.eq(j));
          const className = $child.attr('class').split(' ')[0];
          targ.find(`.${className}`).equalHeights();
        }

        // Ajouter une classe si la section a des éléments de comparaison
        if (comp.children().length) {
          comp.addClass('has-compare');
        }
      }
    }
  });
