export default angular
  .module('directive.owl-carousel', [])

  /* @ngInject */
  .directive('owlCarousel', function ($injector) {
    return {
      restrict: 'A',
      link: async (scope, element, attrs) => {
        const response = await import(/* webpackChunkName: "owlcarousel" */ 'import/js/directives/owlcarousel/owlcarousel.js');
        $injector.loadNewModules([response.default.name]);
        const service = $injector.get('CarouselService');
        service.init(scope, element, attrs);
      }
    };
  });
