import 'Modules/Capency/components/component.capdropdown.tpl';
import 'Modules/Capency/components/component.capdropdown.less';
import { CapDropdown } from '../helpers/capdropdown';

/**
 * @class angular.capadresse.capSearchStreet
 * @desc Composant angularJS : affiche une liste de propositions d'adresses et numéros de rue sous un champ de saisie
 * @memberOf angular.capadresse
 */
/* @ngInject */
class CapSearchStreet extends CapDropdown {
  constructor($scope, $element, $transclude, $timeout, capencyService) {
    super($scope, $element, $transclude, $timeout);

    this.scope = $scope;
    this.capService = capencyService;

    this.selectingNumber = false;
  }

  $onInit() {
    if (!this.formCtrl) {
      this.disable();
      return;
    }

    this.formCtrl.capSearchStreet = {
      clear: this.clear.bind(this),
      getOption: () => this.selection,
      focus: () => this.input.focus()
    };

    this.linkFields();
  }

  linkFields() {
    const linkedFields = ['capBuilding'];

    this.modelCtrl.$viewChangeListeners.unshift(() => {
      const afterSelection = this.modelCtrl.$viewValue === this.parseOption(this.selection);

      if (afterSelection || this.cleared) {
        return;
      }

      const country = this.formCtrl.capCountry.$modelValue;

      if (this.capService.specificCountries.has(country)) {
        return;
      }

      linkedFields.forEach(field => {
        if (field in this.formCtrl) {
          this.formCtrl[field].clear();
        }
      });
    });
  }

  /**
   * What to display from the option on the dropdown
   * @param {CapAddressOption} option
   */
  displayOption(option) {
    return option.inputOutput;
  }

  /**
   * Fetches the list of options to display as a dropdown
   * Either streets or street numbers, depending of user inputs
   * @param {string} userInput
   */
  async fetchList(userInput) {
    this.selectingNumber = this.selectingNumber && !this.isIncomplete(userInput);

    if (this.selectingNumber && !!this.selection) {
      return this.fetchStreetNumbers(this.selection, userInput);
    }

    const country = this.formCtrl.capCountry.$modelValue;
    const countryId = angular.isObject(country) ? country.IDCountry : country;

    const { locality, localityId, postalCode } = this.formCtrl.capSearchLocality.getOption() || {};

    return this.capService.fetchStreets(userInput, { country: countryId, postalCode, locality, localityId }, true);
  }

  /**
   * Fetches a list of street numbers available for currently selected street
   * @param {CapAddressOption} option
   * @param {string} userInput
   */
  async fetchStreetNumbers(option, userInput) {
    const country = this.formCtrl.capCountry.$modelValue;
    const countryId = angular.isObject(country) ? country.IDCountry : country;

    const { localityId, streetId } = option;

    const numbers = userInput && userInput.match(/\d/gi);
    const input = numbers && numbers.join('');

    return this.capService.fetchStreetNumbers(input, { countryId, localityId, streetId });
  }

  /**
   * Determines if the current userInput corresponds to an incomplete street value
   * Basically: User has erased a part of previously selected stree
   * @param {string} userInput
   */
  isIncomplete(userInput) {
    if (!this.selection) {
      return false;
    }

    const selectedValue = this.parseOption(this.selection);
    if (selectedValue === userInput) {
      return false;
    }

    return userInput.length < selectedValue.length;
  }

  /**
   * Parse the value to save in the ngModel from the capAdress result
   * @param {CapAddressOption} option
   */
  parseOption(option) {
    if (option === undefined) {
      return;
    }

    const street = [option.streetNumber, option.streetName];
    if ('streetNumberExt' in option) {
      street.splice(1, 0, option.streetNumberExt);
    }

    return street.join(' ');
  }

  /**
   * Triggered on user selection
   * @param {CapAddressOption} option
   * @param {number} index
   */
  select(option, index) {
    if (this.selectingNumber) {
      if (option === undefined) {
        return;
      }

      this.selectingNumber = false;
      this.selectNumber(option);

      return;
    }

    super.select(option, index);

    const linkedFields = ['capSearchLocality', 'capSearchCity'];

    linkedFields.forEach(field => {
      if (!this.formCtrl[field]) {
        return;
      }

      this.formCtrl[field].setOption(option, index);
    });

    if ('buildingName' in option) {
      this.setBuilding(option.buildingName);
    }

    if ('streetNumber' in option || !('streetName' in option)) {
      return;
    }

    this.selectingNumber = true;

    return this.fetchStreetNumbers(option).then(numbers => {
      if (!numbers || !numbers.length) {
        return;
      }

      this.options = numbers;
      this.scope.$apply(this.open.bind(this));

      // dropdown management
      this.targeted = true;
      this.input.focus();
    });
  }

  /**
   * Sets the form's building field's value ("batiment")
   * @param {string} building
   */
  setBuilding(building) {
    const buildingFieldLoaded = 'capBuilding' in this.formCtrl && this.formCtrl.capBuilding.setValue;

    if (buildingFieldLoaded) {
      this.formCtrl.capBuilding.setValue(building);
    }

    if (!('capTrigger' in this.formCtrl)) {
      return;
    }

    this.formCtrl.capTrigger.open();

    if (buildingFieldLoaded) {
      return;
    }

    this.formCtrl.capBuilding = {
      value: building,
      clear: () => {}
    };
  }

  /**
   * Triggered when user selects a value from streetNumbers options
   * Only for 2-step selection
   * @param {CapAddressOption} option
   */
  selectNumber(option) {
    this.selection.streetNumber = option.streetNumber;

    if ('streetNumberExt' in option) {
      this.selection.streetNumberExt = option.streetNumberExt;
    }

    super.select(this.selection);
  }
}

export default angular
  .module('component.capSearchStreet', [])
  .controller('capSearchStreetCtrl', CapSearchStreet)
  .component('capSearchStreet', {
    templateUrl: 'Modules/Capency/components/component.capdropdown.tpl',
    transclude: true,
    require: {
      formCtrl: '?^^capForm'
    },
    controller: 'capSearchStreetCtrl'
  });
