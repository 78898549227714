export default angular
  .module('directive.popuponeshot', [])

  /* @ngInject */
  .directive('popupOneShot', function ($cookies, ModalService, DateService) {
    return {
      restrict: 'A',
      link: (scope, element, attrs) => {
        if ($cookies.get('popupOneShot')) return;

        const nbClick = Number(attrs.popupOneShot);
        let inc = 0;

        if (nbClick < 0) return;

        if (nbClick === 0) {
          open();
          return;
        }

        $(document.body).on('click', onClick);

        function onClick() {
          inc++;
          if (inc === nbClick) {
            open();
            $(document.body).off('click', onClick);
          }
        }

        function open() {
          ModalService.show(
            '/Template/Modal/ModalPopupOneShot',
            {
              windowClass: 'modal-one-shot'
            },
            null,
            null,
            null,
            scope.device
          );
          $cookies.put('popupOneShot', 'true', {
            path: '/',
            expires: DateService.tomorrow(),
            samesite: 'lax'
          });
        }
      }
    };
  });
