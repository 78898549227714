export default angular
  .module('directive.sticky', [])

  /* @ngInject */
  .directive('sticky', function () {
    return {
      restrict: 'A',
      link: (scope, element, attrs) => {
        if (!window.IntersectionObserver) return;

        const _element = element[0];
        const observer = addObserver();
        let _sentinel;

        if (angular.isDefined(attrs.enable)) {
          scope.$watch(attrs.enable, value => {
            if (angular.isUndefined(value)) return;
            value ? stick() : unstick();
          });
        } else {
          stick();
        }

        function stick() {
          if (!_sentinel) {
            const div = document.createElement('div');
            div.id = `${_element.id}_top`;
            _sentinel = _element.parentNode.insertBefore(div, _element);
          }
          _element.classList.add('sticky');
          observer.observe(_sentinel);
        }

        function unstick() {
          _sentinel && observer.unobserve(_sentinel);
          _element.classList.remove('is-sticky');
          _element.classList.remove('sticky');
        }

        function addObserver() {
          return new IntersectionObserver(
            records => {
              for (const record of records) {
                const targetInfo = record.boundingClientRect;
                const rootBoundsInfo = record.rootBounds;
                if (rootBoundsInfo) {
                  if (targetInfo.bottom < rootBoundsInfo.top) {
                    toggle(true);
                  } else if (targetInfo.bottom >= rootBoundsInfo.top && targetInfo.bottom < rootBoundsInfo.bottom) {
                    toggle(false);
                  }
                }
              }
            },
            { threshold: [0], root: null }
          );
        }

        function toggle(value) {
          _element.classList.toggle('is-sticky', value);
        }
      }
    };
  });
