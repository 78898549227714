
import 'Modules/Payment/import/alma.service.js';

export default angular
  .module('directive.alma.widget', ['service.alma'])

  .directive('almaWidget', function () {
    return {
      restrict: 'E',
      controllerAs: 'almaCtrl',
      bindToController: {
        amount: '<',
        idCard: '<'
      },
      controller: function ($window, AlmaService) {
        const ctrl = this;
        const Alma = $window.AlmaWidgets;
        const Widgets = Alma.Widgets;
        let widget;
        let apiInfo;
        let isInit = false;

        ctrl.$onInit = async () => {
          apiInfo = await AlmaService.getApiInfo();
          const mode = apiInfo.IsProd ? Alma.ApiMode.LIVE : Alma.ApiMode.TEST;

          widget = Widgets.initialize(
            apiInfo.MerchantID, // ID marchand
            mode, // mode de l'API (LIVE ou TEST)
          );

          await setupWidget();
          isInit = true;
        };

        /**
        * Se lance à chaque changement d'un binding (ici amount)
        */
        ctrl.$onChanges = async function (changes) {
          if ((changes.amount && changes.amount.isFirstChange()) || !isInit) return;
          // ne pas lancer au oninit, mais juste quand la valeur du total change
          await setupWidget();
        };

        /**
         * Met en place l'objet widget avec les éligibilités
         */
        async function setupWidget() {
          widget.add(Widgets.PaymentPlans, {
            container: '#alma-widget',
            purchaseAmount: ctrl.amount * 100,
            locale: 'fr',
            hideIfNotEligible: true,
            transitionDelay: 5500
          });
        }

      }
    };
  });
