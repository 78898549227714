export default angular
  .module('directive.catalog-list', [])

  /* @ngInject */
  .directive('catalogList', function () {
    return {
      restrict: 'E',
      bindToController: {
        device: '<'
      },
      controllerAs: 'catalogListCtrl',
      /* @ngInject */
      controller: function ($scope) {
        const ctrl = this;

        ctrl.goToSlide = function (slideNumber) {
          $('#catalogSlider').trigger('to.owl.carousel', slideNumber);
        };
      }
    };
  });
