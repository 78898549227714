angular
  .module('account-check.directive', [])

/* Utilisation de https://regexlib.com/ et https://rgxdb.com/ */

/* @ngInject */
  .directive('accountPhone', function ($timeout, $parse, $q, PhoneService) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, ngModel) {
        const stdPhoneMessage = 'Le numéro de téléphone renseigné<br /> ne correspond pas à un numéro <br /> de téléphone fixe'; // France métropolitaine
        const spePhoneMessages = {
          'BE': 'Le numéro de téléphone renseigné <br/> ne correspond pas à un numéro <br /> de téléphone fixe belge', // 27 => Belgique
          'DE': 'Die eingegebene Telefonnummer <br/> entspricht keiner Festnetznummer', // 8 => Allemagne
          'IT': 'Il numero di telefono inserito <br/> non corrisponde a un numero <br /> di rete fissa', // 114 => Italie
          'ES': 'El número de teléfono introducido <br/> no corresponde a un número <br /> de teléfono fijo', // 72 => Espagne
          'CH': 'Le numéro de téléphone renseigné <br/> ne correspond pas à un numéro <br /> de téléphone fixe', // 215 => Suisse
          'GB': 'The telephone number entered <br/> does not correspond to <br /> a landline number' // 189 => Royaume-Uni
        };
        let countryCode = 'FR';

        const watcher = scope.$watch(attrs.accountPhone, async function (value) {
          countryCode = await PhoneService.getCountryCode(value);
          refreshField(countryCode);
          if (attrs.ngModel) {
            const val = ngModel.$viewValue;
            $parse(attrs.ngModel).assign(scope, val);
          }
        });

        scope.$on('$destroy', function () {
          watcher();
        });

        ngModel.$asyncValidators.phone = function (modelValue) {
          return PhoneService.isValidPhone(modelValue, countryCode, false).then(isValid => {
            if (!isValid) {
              return $q.reject();
            }
            return isValid;
          });
        };

        function refreshField(country) {
          ngModel.$setValidity('phone', true);
          $timeout(function () {
            scope.$apply(function () {
              const message = (country in spePhoneMessages) ? spePhoneMessages[country] : stdPhoneMessage;
              attrs.$set('phone-notification', message);
              ngModel.$validate();
            });
          });
        }
      }
    };
  })

  /* @ngInject */
  .directive('accountMobile', function ($timeout, $parse, $q, PhoneService) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, ngModel) {
        const stdMobileMessage = 'Le numéro de téléphone renseigné <br /> ne correspond pas à un numéro <br /> de téléphone portable'; // France métropolitaine
        const speMobileMessage = {
          'BE': 'Le numéro de téléphone renseigné <br/> ne correspond pas à un numéro <br /> de téléphone portable', // 27 => Belgique
          'DE': 'Die eingegebene Telefonnummer <br/> entspricht keiner Mobiltelefonnummer', // 8 => Allemagne
          'IT': 'Il numero di telefono inserito <br/> non corrisponde a un numero di cellulare', // 114 => Italie
          'ES': 'El número de teléfono introducido <br/> no corresponde a un número <br /> de teléfono móvil', // 72 => Espagne
          'CH': 'Le numéro de téléphone renseigné<br/> ne correspond pas à un numéro<br/> de téléphone portable', // 215 => Suisse
          'GB': 'The telephone number entered <br/> does not correspond to a <br /> mobile telephone number' // 189 => Royaume-Uni
        };
        let countryCode = 'FR';

        const watcher = scope.$watch(attrs.accountMobile, async function (value) {
          countryCode = await PhoneService.getCountryCode(value);
          refreshField(countryCode);
          if (attrs.ngModel) {
            const val = ngModel.$viewValue;
            $parse(attrs.ngModel).assign(scope, val);
          }
        });

        scope.$on('$destroy', function () {
          watcher();
        });

        ngModel.$asyncValidators.mobile = function (modelValue) {
          return PhoneService.isValidPhone(modelValue, countryCode, true).then(isValid => {
            if (!isValid) {
              return $q.reject();
            }
            return isValid;
          });
        };

        function refreshField(country) {
          ngModel.$setValidity('mobile', true);
          $timeout(function () {
            scope.$apply(function () {
              const message = (country in speMobileMessage) ? speMobileMessage[country] : stdMobileMessage;
              attrs.$set('mobile-notification', message);
              ngModel.$validate();
            });
          });
        }
      }
    };
  })

  /* @ngInject */
  .directive('disallowSpaces', function () {
    return {
      restrict: 'A',

      link: function ($scope, $element) {
        $element.bind('input', function () {
          $(this).val($(this).val().replace(/ /g, ''));
        });
      }
    };
  });
