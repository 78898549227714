import { getObjectPropertyFromKey } from 'import/js/functions.js';

export default angular
  .module('service.gtm-enhanced', [])

  /* @ngInject */
  .factory('GTMEnhancedService', function (HttpService, AppService, RoutesService) {
    let clickState = false;
    let params;

    const service = {
      pushEnhancedClick,
      pushEnhancedImpression,
      pushEnhancedAddToCart,
      pushEnhancedRemoveFromCart,
      pushEnhancedCheckout,
      switchClickState,
      getGTMData
    };
    return service;

    // Check is Enhanced Ecommerce module is enable
    async function checkModuleEnable() {
      if (!params) {
        params = await AppService.getParams();
      }
      if (params.Site.UseEnhancedEcommerce) {
        return true;
      }
      return false;
    }

    // Enhanced Ecommerce - Envoie les données liées au clique d'une vignette produit
    async function pushEnhancedClick(product) {
      const enable = await checkModuleEnable();
      if (!enable) {
        return;
      }
      // On récupère la catégorie du produit
      const promise = HttpService.post({
        url: '/Category/GetCategoryNameByProduct',
        data: product.id
      });

      // Une fois la promesse résolue, on envoie les données
      promise.then(value => {
        dataLayer.push({
          event: 'eeproductclick',
          ecommerce: {
            click: {
              actionField: { list: 'Search Results' },
              products: [
                {
                  name: product.designation,
                  id: product.id,
                  price: product.Price.TTCDiscountedPrice,
                  brand: product.Brand,
                  category: value
                }
              ]
            }
          },
          eventCallback: function () {
            document.location = product.URL;
          }
        });
        // On réinitialise le statut du clique
        switchClickState();
      });
    }

    // Enhanced Ecommerce - Envoie les données des impressions de produit pour une catégorie donnée
    async function pushEnhancedImpression(idCategory, enhancedProducts, origin) {
      const enable = await checkModuleEnable();
      if (!enable) {
        return;
      }

      const products = [];

      const promise = HttpService.post({
        url: '/Category/GetCategoryName',
        data: idCategory
      });

      promise.then(value => {
        // Une fois la promesse résolue, on met à jour les produits avec le nom de la catégorie
        for (let i = 0; i < enhancedProducts.length; i++) {
          products.push({
            id: enhancedProducts[i].Reference,
            name: enhancedProducts[i].Designation,
            category: value,
            price: enhancedProducts[i].Price.TTCDiscountedPrice,
            brand: enhancedProducts[i].Brand.Designation,
            list: origin,
            quantity: 1,
            url: enhancedProducts[i].AbsoluteURL,
            position: i
          });
        }

        // Envoie des données d'impressions des produits de la catégorie
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          event: 'eeproductimpressions',
          ecommerce: {
            currencyCode: 'EUR',
            impressions: {
              products
            }
          }
        });
      });
    }

    // Enhanced Ecommerce - Envoie les données liées au panier
    async function pushEnhancedCheckout(enhancedProducts) {
      const enable = await checkModuleEnable();
      if (!enable) {
        return;
      }
      const products = [];
      for (let i = 0; i < enhancedProducts.length; i++) {
        products.push({
          id: enhancedProducts[i].Product.Reference,
          name: enhancedProducts[i].Product.Designation,
          price: enhancedProducts[i].Price.TTCDiscountedPrice,
          brand: enhancedProducts[i].Product.Brand ? enhancedProducts[i].Product.Brand.Designation : null,
          quantity: enhancedProducts[i].Quantity,
          url: enhancedProducts[i].Product.AbsoluteURL,
          position: i
        });
      }

      dataLayer.push({
        event: 'eecheckout',
        ecommerce: {
          checkout: {
            actionField: { step: 1, option: '' },
            products
          }
        },
        eventCallback: 'function () { document.location = \'checkout.html\' }'
      });
    }

    // Enhanced Ecommerce - Envoie les données liées à l'ajout au panier d'un article
    async function pushEnhancedAddToCart(ctrl, quantity) {
      const enable = await checkModuleEnable();
      if (!enable) {
        return;
      }
      dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'EUR',
          add: {
            products: [
              {
                name: ctrl.designation,
                id: ctrl.id,
                price: ctrl.price ? ctrl.price.TTCDiscountedPrice : ctrl.Price.TTCDiscountedPrice,
                quantity
              }
            ]
          }
        }
      });
    }

    // Enhanced Ecommerce - Envoie les données liées à la suppression d'un article dans le panier
    async function pushEnhancedRemoveFromCart(ctrl) {
      const enable = await checkModuleEnable();
      if (!enable) {
        return;
      }
      dataLayer.push({
        event: 'removeFromCart',
        ecommerce: {
          remove: {
            products: [
              {
                name: ctrl.designation,
                id: ctrl.id,
                price: ctrl.price ? ctrl.price.TTCDiscountedPrice : ctrl.Price.TTCDiscountedPrice,
                quantity: Math.abs(ctrl.cartQuantity)
              }
            ]
          }
        }
      });
    }

    // Permet de mofidier le statut du clique, indiquant si on vient de cliquer sur une vignette produit
    function switchClickState() {
      clickState = !clickState;
    }

    /**
      * Fonction permettant de récupérer auprès du back un ensemble de propriétés
      * du contexte visiteur en fonction d'une liste de clés passées, si ces dernières ne sont pas déjà renseignées dans
      * l'instance de contexte du front
      * @param {string[]} keyList La liste des clés dont on veut récupérer la valeur dans le contexte visiteur back
      * @param {any} visitorContext L'instance de contexte visiteur du front
      */
    async function getGTMData(keyList, visitorContext) {
      const gtmData = {};
      const propertiesToGetKeyList = [...keyList];
      let numberOfKeysRemoved = 0;

      //on parcoure la liste de clés et on regarde si le VisitorContext possède
      //la valeur liée
      keyList.forEach((k, i) => {
        const value = getObjectPropertyFromKey(k, visitorContext);

        //si la valeur est déjà présente dans le contexte visiteur
        //on la récupère dans celui ci et on l'enlève de la liste des clés
        if (typeof value !== 'undefined') {
          gtmData[k] = value;
          propertiesToGetKeyList.splice(i - numberOfKeysRemoved++, 1);
        }
      });

      const toSend = {
        url: RoutesService.getUrlByName('GetGTMValuesFromKeyList'),
        data: propertiesToGetKeyList
      };

      //on merge les deux objets et on renvoie le tout
      return Object.assign(gtmData, await HttpService.post(toSend).then(v => v));
    }
  });
