export default angular
  .module('component.comparator', [])

  /* @ngInject */
  .component('comparator', {
    bindings: {
      device: '<'
    },
    /* @ngInject */
    templateUrl: function ($sce) {
      return $sce.trustAsResourceUrl('/Template/Comparator/Comparator');
    },
    /* @ngInject */
    controller: function ($scope, $element, $injector) {
      const ctrl = this;

      ctrl.$onDestroy = () => {};

      ctrl.$onInit = async () => {
        const response = await import(/* webpackChunkName: "comparator" */ 'Modules/Shared/Comparator/import/comparator.js');
        $injector.loadNewModules([response.default.name]);
        const service = $injector.get('ImportComparator');
        service.init(ctrl, $scope, $element);
      };
    }
  });
