export default angular
  .module('component.addtoquotation.advanced', [])

  .component('buttonAddToQuotationAdvanced', {
    bindings: {
      isProductButton: '<?',
      id: '<idProduct',
      isPrimary: '<',
      url: '@productUrl',
      titleTxt: '@',
      titleAdd: '@',
      tooltipAdd: '@',
      tooltipChoice: '@?',
      message: '@'
    },
    template: '',
    /* @ngInject */
    controller: function ($scope, $element, $injector, $compile) {
      const ctrl = this;

      ctrl.$onInit = () => {
        loadTemplate();
      };

      async function loadTemplate() {
        const response = await import(/* webpackChunkName: "addtoquotation.advanced" */ 'import/js/components/addtoquotation/component.addtoquotation.advanced.html');
        $element.html(response.default);
        $compile($element.contents())($scope);
        init();
      }

      async function init() {
        const response = await import(/* webpackChunkName: "addtoquotation.advanced.init" */ 'import/js/components/addtoquotation/component.addtoquotation.advanced.init.js');
        $injector.loadNewModules([response.default.name]);
        const service = $injector.get('InitAddtoquotationAdvanced');
        service.init(ctrl, $scope);
      }
    }
  });
