/**
 * angular-spinner version 0.8.1
 * https://github.com/urish/angular-spinner
 */
import { Spinner } from 'import/js/plugins/spin.js';

export default angular
  .module('module.spinner', [])

  .provider('usSpinnerConfig', function () {
    let _config = {};
    const _themes = {};
    let _delay = 0;

    return {
      setDefaults: config => {
        _config = config || _config;
      },
      setTheme: (name, config) => {
        _themes[name] = config;
      },
      setDelay: delay => {
        _delay = delay;
      },
      $get: () => ({
        config: _config,
        themes: _themes,
        delay: _delay
      })
    };
  })

  /* @ngInject */
  .directive('usSpinner', function (usSpinnerConfig) {
    return {
      scope: true,
      link: (scope, element, attr) => {
        let timeout;
        scope.spinner = null;

        function stopSpinner() {
          if (scope.spinner) {
            element.children().removeClass('in').addClass('out');
            clearTimeout(timeout);
            timeout = setTimeout(() => {
              if (scope.spinner) {
                scope.spinner.stop();
              }
              element.children('.overlay').remove();
            }, scope.delay);
          }
        }

        scope.spin = () => {
          if (scope.spinner) {
            element.children().removeClass('out');
            scope.spinner.spin(element[0]);
            clearTimeout(timeout);
            timeout = setTimeout(() => {
              element.children().addClass('in');
            });
          }
          $('<div class="overlay"/>').appendTo(element);
        };

        scope.stop = () => {
          stopSpinner();
        };

        const options = angular.extend({}, usSpinnerConfig.config, usSpinnerConfig.themes[attr.spinnerTheme]);
        scope.delay = usSpinnerConfig.delay;
        scope.spinner = new Spinner(options);
        if (!scope.key && !attr.spinnerOn) {
          scope.spinner.spin(element[0]);
        }

        scope.$watch(attr.spinnerOn, spin => {
          if (spin) {
            scope.spin();
          } else {
            scope.stop();
          }
        });

        scope.$on('$destroy', () => {
          scope.stop();
          scope.spinner = null;
        });
      }
    };
  });
