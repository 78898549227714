import { inRange } from 'lodash-es';
import cartPostProcess from 'import/js/services/cart.postprocess.js';

export default angular
  .module('service.app', [])
  .factory('AppService', AppService);

AppService.$inject = ['$q', '$rootScope', '$templateCache', 'HttpService', 'ModalService', 'RoutesService'];

function AppService($q, $rootScope, $templateCache, HttpService, ModalService, RoutesService) {
  let appPromise;
  let translate;
  let MobilityVisitorContext;
  let allParams;

  const service = {
    getParams,
    resetParams,
    updateParams,
    getTranslate,
    getProductPrice,
    updateStore,
    updateKey,
    emailExists,
    updateSecurityToken,
    getAllParams
  };

  return service;

  // Cette fonction est utilisée pour récupérer les paramètres "statiques" du site web, à ne pas utiliser pour des paramètres dynamiques (ex: Cart)
  // Elle permet également de récupérer un paramètre spécifique.
  async function getAllParams(searchedParameter = '') {
    try {
      // Si les paramètres ne sont pas encore récupérés, effectue un appel HTTP pour les obtenir.
      if (!allParams) {
        allParams = await HttpService.get({
          url: RoutesService.getUrlByName('AppParams'),
          cache: true
        });
      }

      // Si aucun paramètre spécifique n'est fourni, retourne l'ensemble des paramètres.
      if (searchedParameter === '') {
        return allParams;
      }

      // Recherche le paramètre spécifique parmi les paramètres récupérés. Si ce dernier est identifié dans l'une des structures imbriquées, retourne la valeur correspondante ; sinon, renvoie null.
      const findParam = (obj, paramName) => {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            if (angular.isObject(obj[key])) {
              const result = findParam(obj[key], paramName);
              if (result !== null) {
                return result;
              }
            } else if (key === paramName) {
              return obj[key];
            }
          }
        }
        return null;
      };

      return findParam(allParams, searchedParameter);
    } catch (error) {
      // En cas d'erreur, affiche l'erreur dans la console et la propage.
      console.error(error);
      throw error;
    }
  }

  /**
   * Obtient les paramètres de l'application, en utilisant une promesse pour gérer les appels asynchrones.
   * @param {boolean} refresh - Indique si un rafraîchissement forcé est nécessaire.
   * @returns {Promise} Une promesse qui se résout avec les données de l'application.
   */
  async function getParams(refresh) {
    // Vérifie si un rafraîchissement est nécessaire et si la promesse existe déjà.
    if (!refresh && !angular.isUndefined(appPromise)) {
      return appPromise;
    }

    // Si appPromise n'est pas définie, cela signifie que la fonction est appelée pour la première fois,
    // donc nous effectuons une récupération complète des données en définissant refresh sur true.
    refresh = angular.isUndefined(appPromise) ? true : refresh;

    try {
      const timestamp = refresh ? `?${new Date().getTime()}&refresh=true` : '';
      const response = await HttpService.get({
        // Crée une URL avec un timestamp unique pour contourner les mécanismes de mise en cache des navigateurs (IE11).
        url: `/App${timestamp}`,
        keepDataObject: true
      });

      // Diffuse des événements pour mettre à jour le contexte du visiteur.
      $rootScope.$broadcast('updateVisitorContext', response.data.VisitorContext);
      $rootScope.$broadcast('getParams', response.data.VisitorContext.IsLogged);

      // Copie les données du contexte du visiteur et effectue des transformations nécessaires.
      const data = { ...angular.copy(response.data.VisitorContext), Mobility: response.data.MTYVisitorContext };
      data.Cart = cartPostProcess(data.Cart);

      // Efface le cache du modèle en fonction du rafraîchissement.
      clearTemplateCache(refresh);

      // Résout la promesse avec les données.
      appPromise = $q.resolve(data);
      return appPromise;
    } catch (error) {
      throw error;
    }
  }

  function resetParams() {
    appPromise = undefined;
  }

  // Fonction asynchrone pour mettre à jour les paramètres
  function updateParams(data, noevent) {
    // Effectue un post-traitement sur la propriété Cart
    data.Cart = cartPostProcess(data.Cart);

    // Si la mobilité n'est pas définie dans les données et que MobilityVisitorContext existe, utilisez MobilityVisitorContext
    if (!data.Mobility && MobilityVisitorContext) {
      data.Mobility = MobilityVisitorContext;
    }

    // Crée une promesse résolue avec les données
    appPromise = $q.resolve(data);

    // Si noevent est vrai, retourne immédiatement sans déclencher d'événements
    if (noevent) return;

    // Diffuse un événement 'cartUpdate'
    $rootScope.$broadcast('cartUpdate');

    // Si la propriété 'Store' existe dans les données, diffuse un événement 'storeUpdate'
    if (data.Store) {
      $rootScope.$broadcast('storeUpdate', null, { VisitorContext: data });
    }
  }

  function updateSecurityToken(data, token, name = 'securitytoken') {
    if (token) {
      if (data) {
        data.Token = token;
      }
      const hSecurityToken = $(`input:hidden[name=\"${name}\"]`);

      if (hSecurityToken) {
        hSecurityToken.val(token);
      }
    }
  }

  async function getTranslate() {
    if (angular.isUndefined(translate)) {
      try {
        const { data } = await HttpService.get({
          url: '/Template/Translate/Translate',
          keepDataObject: true
        });
        translate = data;
      } catch (error) {
        throw error;
      }
    }
    return translate;
  }

  function getProductPrice(priceObj, qty) {
    if (!priceObj.prices) {
      const prices = [
        {
          qty: 1,
          range: [0],
          HasDiscount: priceObj.HasDiscount,
          Discount: priceObj.Discount,
          HTDiscountedPrice: priceObj.HTDiscountedPrice,
          TTCDiscountedPrice: priceObj.TTCDiscountedPrice,
          HTPrice: priceObj.HTPrice,
          TTCPrice: priceObj.TTCPrice,
          DegressivePrice: priceObj.DegressivePrice
        }
      ];
      if (Object.entries(priceObj.DegressivePrice).length) {
        let i = 0;
        Object.entries(priceObj.DegressivePrice).forEach(([key]) => {
          const item = priceObj.DegressivePrice[key];
          prices[i].range.push(Number(key));

          prices.push({
            qty: Number(key),
            range: [Number(key)],
            HasDiscount: item.HasDiscount,
            Discount: item.Discount,
            HTDiscountedPrice: item.HTDiscountedPrice,
            TTCDiscountedPrice: item.TTCDiscountedPrice,
            HTPrice: item.HTPrice,
            TTCPrice: item.TTCPrice
          });

          i++;
        });
      }
      priceObj.prices = prices;
    }

    let current = {};
    priceObj.prices.forEach(price => {
      if (price.range && inRange(qty, price.range[0], price.range[1] || 10000)) {
        current = price;
        return false;
      }
    });
    return current;
  }

  function updateStore(visitorContext) {
    appPromise.$$state.value.Store = visitorContext.Store;
    appPromise.$$state.value.Visitor.Store = visitorContext.Visitor.Store;
  }

  function updateKey(key, object) {
    appPromise.$$state.value[key] = object;
  }

  async function emailExists({ email, openModal, action }) {
    const { IsLogged } = await getParams();
    if (!IsLogged) {
      try {
        const { status, errors } = await HttpService.post({
          url: '/TestMailExist',
          data: {
            Email: email
          }
        });
        if (status === 'ERROR') {
          const errorKey = Object.keys(errors).find(key => key === 'Global' && errors[key].Errors[0].ErrorMessage === 'AccountExist');
          if (errorKey !== undefined) {
            if (openModal) {
              ModalService.close();
              const options = {
                mailRecognized: email
              };
              if (action) {
                options.action = action;
              }
              ModalService.show('/Template/Authentication/ModalAuthentication', options, null, 'loginModalCtrl');
            }

            return {
              exists: true,
              errors: errors[errorKey].Errors
            };
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    return {
      exists: false
    };
  }

  // Private
  function clearTemplateCache(refresh) {
    if (!refresh) return;

    const keys = [...$templateCache.getKeys()];
    keys.forEach(key => {
      if (key.indexOf('/') === 0 && !key.includes('ModalAddToCart')) {
        $templateCache.remove(key);
      }
    });
  }
}
