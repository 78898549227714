import 'Modules/Product/import/component.customizable-price-slider.tpl';

export default angular
  .module('component.customizable-price-slider', [])
  /**
   * @class specifics.Product.customizablePriceSlider
   * @memberOf specifics.Product
   * @desc This component displays a price slider for choosing prices on products ( instead of standard select for attributes )
   * @param {string} min Minimal value for the slider
   * @param {string} max Maximal value for the slider
   * @param {string} value Optional : value to initialize the slider with
   * @param {Function} valueChange Callback function to be triggered on slider value change
   * @example
   * <customizable-price-slider min="10" max="100" value="50" value-change="ctrl.onChange"></customizable-price-slider>
   */
  .component('customizablePriceSlider', {
    templateUrl: 'Modules/Product/import/component.customizable-price-slider.tpl',
    bindings: {
      min: '@',
      max: '@',
      value: '@',
      valueChange: '<'
    },
    controllerAs: 'ctrl',
    /* @ngInject */
    controller: function () {
      const ctrl = this;

      ctrl.$onInit = () => {
        if (angular.isUndefined(ctrl.value)) {
          ctrl.price = parseInt(ctrl.min);
          return ctrl.valueChange(`montant_${parseInt(ctrl.min)}`);
        }

        ctrl.price = ctrl.value;
        ctrl.options = {
          floor: parseInt(ctrl.min),
          ceil: parseInt(ctrl.max),
          step: 10,
          showSelectionBar: true,
          translate: value => `${value} €`,
          onEnd: (_sliderId, modelValue) => ctrl.valueChange(`montant_${modelValue}`)
        };
      };
    }
  });
