export default angular
  .module('directive.capTrigger', [])
  /**
   * @class angular.capadresse.capTrigger
   * @memberOf angular.capadresse
   * @desc Directive : Dans le cas où une partie de formulaire serait affichée/cachée de manière dynamique,
   *  cette directive est à ajouter à l'élément déclencheur (ngIf, uibCollapse, etc..) afin de permettre son affichage quand nécessaire
   */
  .directive('capTrigger', function ($timeout) {
    return {
      restrict: 'A',
      scope: false,
      require: '?^^capForm',
      link: (scope, _elt, attrs, formCtrl) => {
        if (!formCtrl) {
          return;
        }

        const triggers = ['ngIf', 'uibCollapse'];

        let triggerVariable;
        const hasTrigger = triggers.some(trigger => {
          if (!(trigger in attrs)) {
            return false;
          }

          triggerVariable = attrs[trigger];
          return true;
        });

        if (!hasTrigger) {
          throw new Error(`One of the directives : ${triggers.join(', ')} is required and missing for CapTrigger directive to work`);
        }

        if (triggerVariable.includes('(')) {
          throw new Error('CapTrigger directive cannot work with functions as opening conditions');
        }

        formCtrl.capTrigger = { open };

        function open() {
          const invert = triggerVariable.substring(0, 1) === '!';
          const trigger = invert ? triggerVariable.substring(1) : triggerVariable;

          $timeout(() =>
            scope.$apply(() => {
              const path = trigger.split('.');
              const opened = 'uibCollapse' in attrs ? invert : !invert;

              write(scope.$parent, path, opened);
            })
          );
        }

        function write(object, path, value) {
          if (path.length === 1) {
            object[path[0]] = value;

            return;
          }

          return write(object[path.shift()], path, value);
        }
      }
    };
  });
