export default angular
  .module('directive.clearuibtab', [])

  .directive('uibTabset', function () {
    return {
      priority: 0,
      // eslint-disable-next-line
      link: (scope, element, attrs) => {
        if (!element.find('uib-tab').length) {
          const $parent = element.parent();
          element.remove();
          if (angular.isDefined(attrs.removeParentIfEmpty)) {
            $parent.remove();
          }
        }
      }
    };
  });
