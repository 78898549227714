import { throttle } from 'lodash-es';

export default angular
  .module('service.window-events', [])

  /* @ngInject */
  .service('WindowEventsService', function ($window) {
    const api = {
      throttleTime: 200,
      listen,
      fn: {}
    };
    return api;

    ////////////
    function listen(isOn, type, method, time) {
      const debounceTime = isNaN(time) ? api.throttleTime : time;
      const listenerType = isOn ? 'addEventListener' : 'removeEventListener';
      if (isOn) {
        api.fn[method] = throttle(method, debounceTime);
      }
      $window[listenerType](type, api.fn[method]);
      if (!isOn) {
        delete api.fn[method];
      }
    }
  });
