import { pullAllBy } from 'lodash-es';

export default angular
  .module('service.cart', [])

  /* @ngInject */
  .factory('CartService', function ($rootScope, $timeout, HttpService, AppService, toastr, GA4Service, NetwaveService) {
    const cartRequests = [];

    const service = {
      addToCart,
      deleteLine,
      itemQuantityInCart,
      checkAvailability,
      cartRequests,
      checkCartEnable
    };
    return service;

    ////////////

    async function addToCart(products, checkCumulativeProduct = false) {
      const productsToCart = {
        Products: products.map(product => ({
          IDLine: product.idLine || 0,
          IDProduct: product.idProduct || 0,
          Reference: product.Reference || '',
          Quantity: product.quantity,
          Comment: product.Comment || '',
          Elements: product.Elements || null,
          Customization: product.Customization || null,
          CustomizablePrice: product.CustomizablePrice ? { Price: product.CustomizablePrice.current } : null
        })),
        CheckCumulativeProduct: checkCumulativeProduct
      };

      const tmpId = new Date().getTime();
      cartRequests.push({ id: tmpId });

      try {
        const response = await HttpService.post({
          url: '/Product/AddToCart',
          data: productsToCart,
          keepDataObject: true
        });

        pullAllBy(cartRequests, [{ id: tmpId }], 'id');

        const product = response.data.results.Cart.Products.find(p => p.IDProduct === products[0].idProduct);

        if (product) {
          products[0].KitUnitTTCPrice = product.Product.IsKit ? Math.round(product.TTCAmount / product.Quantity * 100) / 100 : 0;
          products[0].IsKit = product.Product.IsKit;
        }

        if (products[0]?.quantity > 0) {
          // Add to Cart
          GA4Service.addToCart(response.data?.results?.Cart?.TradeActions, products, response.data?.results?.Currency?.ISOCode, response.data?.affiliation);
          await NetwaveService.addArticleToCart(products[0], products[0].quantity);
        } else {
          // Remove from Cart
          GA4Service.removeFromCart(response.data?.results?.Cart?.TradeActions, products, response.data?.results?.Currency?.ISOCode, response.data?.affiliation);
          await NetwaveService.removeArticleFromCart(products[0], -(products[0].quantity));
        }

        return response.data;
      } catch (error) {
        console.error('Erreur lors de l\'ajout au panier :', error);
        throw error;
      }
    }

    async function deleteLine(idLine, product, designation, img, imgSrc, message, fromCart) {
      try {
        $rootScope.$broadcast('showPageLoader', true);
        const response = await HttpService.get({
          url: `/RemoveCartLine/${idLine}`,
          cache: false
        });

        if (response.status === 'OK') {
          AppService.updateParams(response.results);
          toastr.success(designation, message, {
            allowHtml: true,
            extraData: {
              template: 'import/js/libs/angular-toastr/toast_addtocart.tpl',
              data: {
                img,
                imgSrc
              }
            }
          });

          try {
            // GA4 Remove From Cart
            const currency = response?.results?.Currency?.ISOCode || null;
            GA4Service.removeFromCart(response.results.Cart.TradeActions, [response.removedProducts], currency, response.results.Localization.TradeName);
          } catch (error) {
            console.error('Erreur lors de la gestion de GA4 Remove From Cart :', error);
          }

          // Netwave
          const qty = product.quantity > 0 ? product.quantity : -product.quantity;
          await NetwaveService.removeArticleFromCart(product, qty);
          deleteLineCallback(fromCart, response);
        } else {
          const messages = await AppService.getTranslate();
          toastr.warning(messages.errors.TryLater, messages.errors.ErrorHasOccurred, {
            allowHtml: true,
            extraData: {
              template: 'import/js/libs/angular-toastr/toast_message.tpl'
            }
          });
        }
      } catch (error) {
        console.error('Erreur lors de la suppression de la ligne du panier :', error);
      } finally {
        $rootScope.$broadcast('showPageLoader', false);
      }
    }

    function deleteLineCallback(fromCart, response) {
      if (fromCart && !response.results.Cart.Products.length) {
        $rootScope.$broadcast('showPageLoader', true);
        $timeout(() => {
          $rootScope.$broadcast('showPageLoader', true);
        }, 450);
        window.location = $rootScope.backToStore;
      }
    }

    async function itemQuantityInCart(id, hash = '') {
      try {
        const data = await AppService.getParams();

        if (!data.HasCart) {
          return 0;
        }

        for (const product of data.Cart.Products) {
          if (product.IDProduct === id && (hash === '' || product.Comment === hash)) {
            return product.Quantity;
          }
        }

        return 0;
      } catch (error) {
        console.error('Erreur lors de la récupération de la quantité d\'article dans le panier :', error);
        return 0;
      }
    }

    async function checkAvailability() {
      try {
        const checkResponse = await HttpService.post({
          url: '/Cart/checkAvailability'
        });

        return checkResponse;
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

    async function checkCartEnable() {
      try {
        const response = await HttpService.post({
          url: '/Cart/CheckCartEnable'
        });

        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
  });
