export default angular
  .module('directive.breadcrumb', [])

  /* @ngInject */
  .directive('breadcrumb', function () {
    return {
      restrict: 'A',
      link: (scope, element) => {
        $('#navMenu, #navSecondary, #navAside').find('li.active').removeClass('active');

        const $items = element.find('.item');
        const len = $items.length;
        let i, id;

        for (i = 0; i < len; i++) {
          id = $($items[i]).data('id');
          $('#navMenu, #navSecondary, #navAside').find(`li[data-id="${id}"]`).addClass('active');
        }
      }
    };
  });
