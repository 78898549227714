/* eslint-disable no-undef */

(function () {
  'use strict';

  angular
    .module('module.netwave', [])

    .constant('wsbZonePrefix', 'wsbPZ_')

    /* @ngInject */
    .factory('NetwaveService', function ($rootScope, HttpService, RoutesService) {
      window.NetwaveService = this;
      let netwaveInfos;

      const initializeNetWaveInfo = async function () {
        if (!netwaveInfos) {
          netwaveInfos = await getNetWaveInfo();
        }
      };

      const isNetwaveEnable = async function () {
        await initializeNetWaveInfo();
        return netwaveInfos === null ? false : netwaveInfos.netwaveEnable;
      };

      const getNetWaveInfo = async function () {
        const url = RoutesService.getUrlByName('GetNetWaveInfo');
        let netInfos;
        await HttpService.get({
          url,
          cache: true
        })
          .then(response => { netInfos = response; })
          .catch(function (error) {
            console.error(error);
          });
        return netInfos;
      };

      const getIdProduct = function (product) {
        if (product && product.hasOwnProperty('IDProduct')) {
          return product.IDProduct;
        } else if (product && product.hasOwnProperty('idProduct')) {
          return product.idProduct;
        }
        return null;
      };

      /*
      * TODO delete this function when it will be possible to get a price on the product object
      * calculate product price
      */
      const getPrice = function (product) {
        let price = 0;
        if (product.IsKit && product.KitUnitTTCPrice && product.KitUnitTTCPrice > 0) {
          price = Math.round(product.KitUnitTTCPrice * 100) / 100;
        } else if (product.Product?.IsKit && product.TTCAmount > 0) {
          price = Math.round(product.TTCAmount / product.Quantity * 100) / 100;
        } else if (product.step && product.Price.qty) {
          price = Math.round(product.Price.TTCDiscountedPrice * product.Price.qty * 100) / 100;
        } else if (product.Price?.TTCDiscountedPrice > 0) {
          price = product.Price.TTCDiscountedPrice;
        } else if (product.Price?.TTCPrice > 0 && product.quantity) {
          price = Math.round(product.Price?.TTCPrice / product.quantity * 100) / 100;
        } else {
          price = (product.Price.TTCDiscountedPrice === null ? 0 : product.Price.TTCDiscountedPrice) + (product.costTTC === undefined ? 0 : product.costTTC);
        }
        return price;
      };

      this.trigger = function (data) {
        $rootScope.$broadcast('netwaveUpdate', data);
      };
      this.getProducts = async function (products) {
        return HttpService.post({
          url: '/GetProducts',
          data: {
            ProductsIds: products
          }
        })
          .then(response => response.products
          )
          .catch(function (error) {
            throw error;
          });
      };
      this.updateFilters = function (ctrl) {
        const filters = {};
        ctrl.BrandsSelected?.forEach(function () {
          const filterBrandsSelected = ctrl.filters.brands.find(item => item.Id);
          if (filterBrandsSelected) {
            filters.brands = filters.brands || [];
            filters.brands.push(filterBrandsSelected.Name);
          }
        });
        ctrl.ReviewsSelected?.forEach(function () {
          const filterReviewsSelected = ctrl.filters.reviews.find(item => item.Id);
          if (filterReviewsSelected) {
            filters.reviews = filters.reviews || [];
            filters.reviews.push(filterReviewsSelected.Name);
          }
        });
        ctrl.ProductTypesSelected?.forEach(function () {
          const filterProductTypesSelected = ctrl.filters.types.find(item => item.Id);
          if (filterProductTypesSelected) {
            filters.types = filters.types || [];
            filters.types.push(filterProductTypesSelected.Name);
          }
        });

        ctrl.words?.forEach(function () {
          filters.search = filters.search || [];
          filters.search.push(item);
        });

        if (ctrl.PriceRange) {
          filters.prices = [ctrl.PriceRange];
        }

        const facets = ctrl.FacetsSelected.filter(item => item.Values.length);

        isScriptLoaded().then(filter);

        /**
        * Generate a script that retrieves filter information for category and list pages
        * where it is embedded and then sends it to Netwave.
        * */
        function filter() {
          const wsbProperties = window.wsbProperties || {};
          facets.forEach(function (filterFacet) {
            wsbProperties.filter = {
              categoryId: ctrl.categoryCtrl.idCategory,
              name: (filterFacet.Name).toLowerCase(),
              values: filterFacet.Values
            };
          });
        }
      };

      /*
       * Send delete commands to netwave for all products
       */
      this.emptyCart = async function (products) {
        if (!(await isNetwaveEnable())) return;

        for (const product of products) {
          await window.NetwaveService.removeArticleFromCart(product, product.Quantity);
        }
      };

      /*
       * Send delete command to netwave for a product and a quantity
       */
      this.removeArticleFromCart = async function (product, quantity) {
        if (!(await isNetwaveEnable())) return;

        async function removeArticle(pdt, qty) {
          await window.wsb.removeArticleFromCart(String(pdt.idCategory), String(getIdProduct(pdt)), qty, getPrice(pdt));
        }

        if (!product.idCategory) {
          const idCategory = await window.NetwaveService.getCategory(getIdProduct(product));
          product.idCategory = idCategory;
          await removeArticle(product, quantity);
        } else {
          await removeArticle(product, quantity);
        }
      };

      /*
      * Send add command to netwave for a product and a quantity
      */
      this.addArticleToCart = async function (product, quantity) {
        if (!(await isNetwaveEnable())) return;

        async function addArticle(pdt, qty) {
          await window.wsb.addArticleToCart(String(pdt.idCategory), String(getIdProduct(pdt)), qty, getPrice(pdt));
        }

        if (!product.idCategory) {
          const idCategory = await window.NetwaveService.getCategory(getIdProduct(product));
          product.idCategory = idCategory;
          await addArticle(product, quantity);
        } else {
          await addArticle(product, quantity);
        }
      };

      /*
      * Get product category
      */
      this.getCategory = async function (productId) {
        return HttpService.post({
          url: `/GetCategoryProduct/${productId}`,
          cache: true
        })
          .then(getCategoryResponse => getCategoryResponse.idCategory)
          .catch(function (error) {
            console.error(error);
          });
      };

      /*
       * Send delete commands to netwave for all products in the wishList
       */
      this.emptyWishList = async function (lines) {
        if (!(await isNetwaveEnable())) return;
        if (lines !== null) {
          lines.forEach(function (line) {
            window.NetwaveService.updateWishList(line.Product.IDProduct, false);
          });
        }
      };

      /*
       * Send add/delete commands to netwave for a product
       */
      this.updateWishList = async function (productId, addToWishList) {
        if (!(await isNetwaveEnable())) return;
        window.NetwaveService.getCategory(productId).then(function (idCategory) {
          if (addToWishList) {
            window.wsbProperties.addToWishlist = {
              categoryId: idCategory,
              productId
            };
          } else {
            window.wsbProperties.removeFromWishlist = {
              categoryId: idCategory,
              productId
            };
          }
        });
      };

      /**
      * Wait for the netwave script to load to override the function
      * */
      const isScriptLoaded = async function () {
        while (!window.wsbProperties) {
          await new Promise(resolve => requestAnimationFrame(resolve));
        }
      };

      return {
        getProducts: this.getProducts,
        updateFilters: this.updateFilters,
        emptyCart: this.emptyCart,
        getCategory: this.getCategory,
        updateWishList: this.updateWishList,
        emptyWishList: this.emptyWishList,
        removeArticleFromCart: this.removeArticleFromCart,
        addArticleToCart: this.addArticleToCart
      };
    })


    /* @ngInject */
    .directive('netWaveTabs', function () {
      return {
        restrict: 'A',
        scope: true,
        controllerAs: 'netwaveTabsCtrl',
        controller: function () { }
      };
    })

    /* @ngInject */
    .directive('netWave', function () {
      return {
        restrict: 'A',
        require: {
          productCtrl: '?^^productDetail',
          tabsCtrl: '?^^netWaveTabs'
        },
        scope: true,
        bindToController: {
          id: '@'
        },
        controllerAs: 'netwaveCtrl',
        /* @ngInject */
        controller: function ($rootScope, $element, $scope, $timeout, NetwaveService, AppService, AvailabilityService, PriceService) {
          const ctrl = this;

          // exemple of code zone : wsbPZ_ZH2. We keep ZH2
          ctrl.$onInit = function () {
            ctrl.zone = ctrl.id.split('_');
            ctrl.zone = ctrl.zone[1];
          };

          const unwatch = $scope.$on('netwaveUpdate', async function (event, publishingZones) {
            unwatch();
            const publishingZone = _.find(publishingZones, { publishingZoneId: ctrl.zone });
            if (publishingZone) {
              if (ctrl.tabsCtrl) {
                ctrl.tabsCtrl.active = true;
              }
              const sortedZones = _.sortBy(
                _.filter(publishingZones, function (o) {
                  if (o.json.products === undefined) {
                    return 0;
                  }
                  return o.json.products.length;
                }),
                ['publishingZoneId']
              );
              const index = _.findIndex(sortedZones, { publishingZoneId: ctrl.zone });
              ctrl.isOpen = index === 0;
              ctrl.catchphrase = publishingZone.json.catchphrase;
              ctrl.bannerName = publishingZone.json['Banner name'];
              ctrl.bannerUrl = publishingZone.json['Banner url'];
              ctrl.bannerLink = publishingZone.json.Link;
              const imgWsbPublishingZone = angular.element(document.querySelectorAll('.imgWsbPublishingZone'));
              const linkWsbPublishingZone = angular.element(document.querySelectorAll('.linkWsbPublishingZone'));
              imgWsbPublishingZone.attr('src', ctrl.bannerUrl);
              linkWsbPublishingZone.attr('href', ctrl.bannerLink);
              if (publishingZone.json.products) {
                await getProducts(publishingZone.json.products);
              }
            }
          });

          ctrl.$onDestroy = function () {
            unwatch();
          };

          ctrl.init = function (id) {
            setTimeout(function () {
              const element = document.getElementById(id);
              element.classList.add('wsbRecommendation');
            });
          };

          ctrl.loaded = function () {
            $scope.$broadcast('netwave-loaded');
            $scope.netwaveLoaded = true;
          };

          async function getProducts(products) {
            try {
              const response = await NetwaveService.getProducts(products);

              if (!response || !response.length) return;

              ctrl.products = response.filter(p => p.IDProduct !== 0);

              await Promise.all(ctrl.products.map(async function (product) {
                product.ReviewsInfo = product.ReviewsInfo ? { Count: product.ReviewsInfo.Count, Average: Math.floor(product.ReviewsInfo.Average) } : null;
                product.domId = `wsbR_${product.IDProduct}@${ctrl.zone}`;
                product.isComparable = product.Themes.some(th => th.KeyTheme === 'Comparateur');
                product.Pictogrammes = product.Themes?.filter(th => th.KeyRegrouping === 'Pictogrammes')?.map(th => ({
                  IDTheme: th.IDTheme,
                  IDPicture: th.IDPicture,
                  Designation: th.Designation,
                  Key: th.KeyTheme,
                  Background: th.Text1,
                  Color: th.Text2
                }));

                if (product.Availability) {
                  product.Availability = product.Availability;
                  const { Site } = await AppService.getParams();

                  if (Site.DisplayAvailabilityOnListPage && Site.IsOmnichannelOrder) {
                    await AvailabilityService.formatMessage(product.Availability.Detail, 'Delivery');
                    await AvailabilityService.formatMessage(product.Availability.Detail, 'PickUp');
                    product.Availability.Detail.modalData = {
                      idProduct: product.IDProduct,
                      action: $rootScope.forceReload,
                      quantity: product.MinSaleQuantity !== 0 ? product.MinSaleQuantity : 1
                    };
                  }
                }
                product.htmlPrice = await PriceService.get(product);
              }));

              $timeout(function () {
                ctrl.active = true;
              });
            } catch (error) {
              console.error(error);
            }
          }
        }
      };
    })

    /**
     *Netwave tags for each page
     * */
    /* @ngInject */
    .directive('netwaveTags', function (wsbZonePrefix) {
      return {
        restrict: 'A',
        scope: {
          tags: '=tags'
        },
        link: function (scope) {
          if (scope.tags) {
            isScriptLoaded().then(init);
          }
          function init() {
            overrideDisplay();
            setupNetwaveGlobalParameters(
              scope.tags.Langage,
              scope.tags.StateLoggedIn,
              scope.tags.ClientId,
              scope.tags.StockLocation,
              scope.tags.DataPage,
              scope.tags.CategoryId,
              scope.tags.ProductId,
              scope.tags.SearchRequestArray,
              scope.tags.OrderId,
              scope.tags.CustomerId,
              scope.tags.Total,
              scope.tags.Articles);

            if (scope.tags.DataPage !== 'order' && window.wsb._getMessageQueue().length < 1) {
              window.wsb._addMessage('setDataPage', window.wsb._currentPage, 0);
            }
          }

          /**
          * Wait for the netwave script to load to override the function
          * */
          async function isScriptLoaded() {
            while (!window.wsbDisplay) {
              await new Promise(resolve => requestAnimationFrame(resolve));
            }
          }

          /**
           * Override of wsbDisplay.display function of the Netwave script in order to manage the recovery of products
           * And populates an array (recoElements) with zone product HTML content to send to Netwave.
           * */
          function overrideDisplay() {
            wsbCustomTemplating((publishingZones) => {
              if (window.NetwaveService) {
                window.NetwaveService.trigger(publishingZones);
              }
              publishingZones.forEach((recommendations) => {
                const zoneId = recommendations.publishingZoneId;
                launchNetWaveCallBack(zoneId);
              });
            });
          }

          /**
           * Iteratively, using a promise to check if a zone is currently in the DOM.
           * requestAnimationFrame is exactly the same as setTimeOut only a little more performant
           * Check the status each time the DOM is refreshed
           * @param {any} zone
           */
          async function isZoneExistInDom(zone) {
            while (document.querySelectorAll(`[id$= '@${zone}'][class$='wsbRecommendation']`).length === 0) {
              await new Promise(resolve => requestAnimationFrame(resolve));
            }
          }
          /*
           * Wait until product dom is built, before launch a callback to netwave indicate that a zone is ready
           *
           */
          async function launchNetWaveCallBack(zoneId) {
            await isZoneExistInDom(zoneId);
            wsbPublishCustomTemplating(wsbZonePrefix + zoneId);
          }

          /**
           * Generate a script that retrieves specific information from each page
           * where it is embedded and then sends it to Netwave.
           * @param {!string} Langage the current language of your website for the user
           * @param {!boolean} StateLoggedIn boolean that matches the current user logging state
           * @param {!string} ClientId customer id value for the current user
           * @param {?string} StockLocation the current stock location the user is on
           * @param {?string} DataPage the current page being browsed
           * @param {?number[20]} CategoryId id category for product, category and search page. Also can be an array of multiple categories for search page.
           * @param {?string[30]} ProductId id produit for product or search page
           * @param {?string[]} SearchRequestArray search of the user
           * @param {?string} OrderId Unique order identifier (empty string if unknown) for confirmation page
           * @param {?number} CustomerId Unique customer identifier for confirmation page
           * @param {?number} Total Amount for order (tax,port, discount inclusive) for confirmation page
           * @param {?Object} Articles List of ArticleAddToCart, articles ordered
           */
          function setupNetwaveGlobalParameters(langage, stateLoggedIn, clientId,
            stockLocation, dataPage, categoryId, productId, searchRequestArray, orderId, customerId, total, articles) {

            const wsbProperties = window.wsbProperties || {};
            window.wsbProperties.reset = true;
            wsbProperties.language = langage;
            wsbProperties.loggedIn = stateLoggedIn;
            wsbProperties.clientId = clientId;
            wsbProperties.stockLocation = stockLocation;


            if (dataPage === 'product') {
              wsbProperties[`${dataPage}Page`] = {
                categoryId,
                productId
              };
            } else if (dataPage === 'category') {
              wsbProperties[`${dataPage}Page`] = categoryId;
            } else if (dataPage === 'search') {
              let searchRequest = '';
              for (let i = 0; i < searchRequestArray.length; i++) {
                searchRequest += `${searchRequestArray[i]} `;
              }
              wsbProperties[`${dataPage}Page`] = {
                productId,
                categoryId,
                searchRequest
              };
            } else if (dataPage === 'order') {
              // Changed property naming to match Netwave naming which is in Camel Case
              articles.forEach(function (article) {
                Object.keys(article).forEach(function (key) {
                  if (key !== camelize(key)) {
                    Object.defineProperty(article, camelize(key),
                      Object.getOwnPropertyDescriptor(article, key));
                    delete article[key];
                  }
                });
              });

              wsbProperties[`${dataPage}`] = {
                articles,
                orderId,
                customerId,
                total
              };
            } else {
              wsbProperties[`${dataPage}Page`] = true;
            }
          }

          /**
           * To change a string into Camel Case
           *
           * */
          function camelize(str) {
            return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
              return index === 0 ? word.toLowerCase() : word.toUpperCase();
            });
          }
        }
      };
    });

})();
