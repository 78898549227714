/*
 * angular-lazy-load
 * base https://github.com/Pentiado/angular-lazy-img
 */

export default angular
  .module('module.lazy', [])

  /* @ngInject */
  .factory('lazyObserver', function (lazyHelpers) {
    const service = {
      add,
      remove
    };
    let env, intersectionListener;
    init();
    return service;

    ////////////
    function init() {
      env = lazyHelpers.env;
      if (env.intersectionObserverSupport !== true) return angular.noop;

      intersectionListener = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach(entry => {
            if (entry.isIntersecting === true || entry.intersectionRatio > 0) {
              const element = entry.target;
              observer.unobserve(element);
              loadElement(element);
            }
          });
        },
        {
          rootMargin: '200px 0%'
        }
      );
    }

    function loadElement(element) {
      if (element.tagName.toLowerCase() === 'img') {
        const parentElement = element.parentNode;

        // if the parent element is <picture>
        if (parentElement.tagName.toLowerCase() === 'picture') {
          [].slice.call(parentElement.querySelectorAll('source')).forEach(source => yallFlipDataAttrs(source));
          yallFlipDataAttrs(element);
          return;
        }

        const newImageElement = new Image();
        if (env.asyncDecodeSupport === true && env.replaceWithSupport === true) {
          newImageElement.src = element.getAttribute('lazy');

          newImageElement
            .decode()
            .then(() => {
              for (let i = 0; i < element.attributes.length; i++) {
                const attrName = element.attributes[i].name;
                const attrValue = element.attributes[i].value;

                if (!env.ignoredImgAttributes.includes(attrName)) {
                  newImageElement.setAttribute(attrName, attrValue);
                }
              }
              element.replaceWith(newImageElement);
              newImageElement.removeAttribute('lazy');
              newImageElement.classList.add('loaded');
            })
            .catch(() => {
              yallFlipDataAttrs(element);
            });
        } else {
          yallFlipDataAttrs(element);
        }
      } else if (element.tagName.toLowerCase() === 'iframe') {
        // Lazy load <iframe> elements
        element.src = element.getAttribute('lazy');
        element.removeAttribute('lazy');
      } else if (element.tagName.toLowerCase() === 'div') {
        element.classList.add('loaded');
      }
    }

    function yallFlipDataAttrs(element) {
      const refElement = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      const sourceDataset = refElement.dataset || element.dataset;
      for (const dataAttribute in sourceDataset) {
        if (env.acceptedDataAttributes.includes(`data-${dataAttribute}`)) {
          element.setAttribute(dataAttribute, sourceDataset[dataAttribute]);
          element.removeAttribute(`data-${dataAttribute}`);
        }
      }
      const src = (refElement && refElement.getAttribute('lazy')) || element.getAttribute('lazy');
      if (src) {
        element.src = src;
        (refElement && refElement.removeAttribute('lazy')) || element.removeAttribute('lazy');
      }
      if (element.tagName.toLowerCase() === 'img') {
        element.classList.add('loaded');
      }
    }

    function add(photo) {
      intersectionListener.observe(photo.$elem[0]);
    }
    function remove(photo) {
      intersectionListener.unobserve(photo.$elem[0]);
    }
  })

  /* @ngInject */
  .factory('LazyMagic', function ($window, $rootScope, lazyConfig, lazyHelpers, lazyObserver) {
    let winDimensions, isListening;

    const images = [];
    isListening = false;
    const options = lazyConfig.getOptions();
    const $win = angular.element($window);
    winDimensions = lazyHelpers.getWinDimensions();
    const saveWinOffsetT = lazyHelpers.throttle(() => {
      winDimensions = lazyHelpers.getWinDimensions();
    }, 60);
    const containers = [options.container || $win];
    const checkImagesT = lazyHelpers.throttle(checkImages, 30);

    function checkImages() {
      for (let i = images.length - 1; i >= 0; i--) {
        const image = images[i];

        if (image) {
          const _isInView = !image.$elem.is(':visible') ? false : lazyHelpers.isElementInView(image.$elem[0], options.offset, winDimensions);
          if (_isInView) {
            loadImage(image);
            images.splice(i, 1);
          }
        }
      }
      if (!images.length) stopListening();
    }

    function listen(param) {
      containers.forEach(container => {
        container[param]('scroll', checkImagesT);
        container[param]('touchmove', checkImagesT);
      });
      $win[param]('resize', checkImagesT);
      $win[param]('resize', saveWinOffsetT);
    }

    function startListening() {
      isListening = true;
      setTimeout(() => {
        checkImages();
        listen('on');
      }, 1);
    }

    function stopListening() {
      isListening = false;
      listen('off');
    }

    function removeImage(image) {
      const index = images.indexOf(image);
      if (index !== -1) {
        images.splice(index, 1);
      }
    }

    function loadImage(photo) {
      if (photo.loaded || photo.src === '') return;

      if (photo.src === 'inview') {
        if (options.successClass) {
          photo.$elem.addClass(options.successClass);
        }
        return;
      }

      if (photo.$elem[0].tagName.toLowerCase() === 'iframe') {
        photo.$elem[0].src = photo.src;
        return;
      }

      const img = new Image();
      img.onerror = () => {
        if (options.errorClass) {
          photo.$elem.addClass(options.errorClass);
        }
        $rootScope.$emit('lazy:error', photo);
        options.onError(photo);
      };
      img.onload = () => {
        photo.loaded = true;
        setPhotoSrc(photo.$elem, photo.src, photo.srcset);
        if (options.loadingClass) {
          photo.$elem.removeClass(options.loadingClass);
        }
        if (options.successClass) {
          photo.$elem.addClass(options.successClass);
        }
        $rootScope.$emit('lazy:success', photo);
        options.onSuccess(photo);
      };
      if (options.loadingClass) {
        photo.$elem.addClass(options.loadingClass);
      }
      options.onLoading(photo);

      img.src = photo.src;
    }

    function setPhotoSrc($elem, src, srcset) {
      if ($elem[0].tagName.toLowerCase() === 'img') {
        $elem[0].src = src;
        if (srcset) {
          $elem[0].srcset = srcset;
        }
      } else {
        $elem.css('background-image', `url("${src}")`);
      }
    }

    // PHOTO
    function Photo($elem) {
      this.$elem = $elem;
    }

    Photo.prototype.setSource = function (source) {
      this.src = source;
      const tag = this.$elem[0].tagName.toLowerCase();
      if (lazyHelpers.env.intersectionObserverSupport === true && (tag === 'img' || tag === 'iframe')) {
        lazyObserver.add(this);
      } else {
        images.unshift(this);
        if (!isListening) {
          startListening();
        }
        if (images.length !== 1) {
          setTimeout(() => {
            checkImages();
          }, 1);
        }
      }
    };

    Photo.prototype.removeImage = function () {
      if (lazyHelpers.env.intersectionObserverSupport === true) {
        lazyObserver.remove(this);
      } else {
        removeImage(this);
        if (!images.length) stopListening();
      }
    };

    Photo.prototype.checkImages = checkImages;

    Photo.addContainer = container => {
      stopListening();
      containers.push(container);
      startListening();
    };

    Photo.removeContainer = container => {
      stopListening();
      containers.splice(containers.indexOf(container), 1);
      startListening();
    };

    return Photo;
  })

  /* @ngInject */
  .provider('lazyConfig', function () {
    const options = {
      offset: 100,
      errorClass: null,
      loadingClass: null,
      successClass: null,
      onError() {},
      onLoading() {},
      onSuccess() {}
    };

    this.$get = () => ({
      getOptions: () => options
    });

    this.setOptions = _options => {
      angular.extend(options, _options);
    };
  })

  /* @ngInject */
  .factory('lazyHelpers', function ($window) {
    const testImage = new Image();

    function getWinDimensions() {
      return {
        height: $window.innerHeight,
        width: $window.innerWidth
      };
    }

    function isElementInView(elem, offset, winDimensions) {
      const rect = elem.getBoundingClientRect();
      const bottomline = winDimensions.height + offset;
      return (
        rect.left >= 0 && rect.right <= winDimensions.width + offset && ((rect.top >= 0 && rect.top <= bottomline) || (rect.bottom <= bottomline && rect.bottom >= 0 - offset))
      );
    }

    // http://remysharp.com/2010/07/21/throttling-function-calls/
    function throttle(fn, threshhold, scope) {
      let last, deferTimer;
      return function () {
        const context = scope || this;
        const now = +new Date();
        const args = arguments;
        if (last && now < last + threshhold) {
          clearTimeout(deferTimer);
          deferTimer = setTimeout(() => {
            last = now;
            fn.apply(context, args);
          }, threshhold);
        } else {
          last = now;
          fn.apply(context, args);
        }
      };
    }

    return {
      isElementInView,
      getWinDimensions,
      throttle,
      env: {
        intersectionObserverSupport: 'IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype,
        asyncDecodeSupport: 'decode' in testImage,
        replaceWithSupport: 'replaceWith' in testImage,
        ignoredImgAttributes: ['data-src', 'data-sizes', 'data-media', 'data-srcset', 'src', 'srcset'],
        acceptedDataAttributes: ['data-src', 'data-sizes', 'data-media', 'data-srcset']
      }
    };
  })

  /* @ngInject */
  .directive('lazy', function ($rootScope, LazyMagic) {
    return {
      restrict: 'A',
      link: (scope, element, attrs) => {
        const lazyImage = new LazyMagic(element);

        const deregister = attrs.$observe('lazy', newSource => {
          if (newSource && newSource !== '') {
            deregister();
            lazyImage.setSource(newSource);
          }
        });

        const eventsDeregister = $rootScope.$on('lazy:refresh', () => {
          lazyImage.checkImages();
        });

        scope.$on('$destroy', () => {
          lazyImage.removeImage();
          deregister();
          eventsDeregister();
        });
      }
    };
  })

  /* @ngInject */
  .directive('lazyContainer', function (LazyMagic, lazyHelpers) {
    return {
      restrict: 'A',
      link: (scope, element) => {
        if (lazyHelpers.env.intersectionObserverSupport === true) return;
        LazyMagic.addContainer(element);
        scope.$on('$destroy', () => {
          LazyMagic.removeContainer(element);
        });
      }
    };
  });
