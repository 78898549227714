export default angular
  .module('directive.store', [])

  .directive('store', function () {
    return {
      restrict: 'E',
      bindToController: {
        name: '@',
        latitude: '<',
        longitude: '<',
        model: '<',
        daysModel: '@days',
        strOneTime: '@oneTime',
        strTwoTimes: '@twoTimes',
        strClosed: '@closed',
        device: '<'
      },
      controllerAs: 'storeCtrl',
      /* @ngInject */
      controller: function ($scope, $injector) {
        const ctrl = this;

        ctrl.$onInit = async () => {
          const response = await import(/* webpackChunkName: "store" */ 'Modules/Stores/import/store.js');
          $injector.loadNewModules([response.default.name]);
          const service = $injector.get('ImportStore');
          service.init(ctrl, $scope);
        };
      }
    };
  });
