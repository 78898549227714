export default angular
  /**
   * @class specifics.Account
   * @memberOf specifics
   * @desc Composants spécifiques du module Account
   */
  .module('directive.check.identity', [])
  /**
   * @class specifics.Account.checkIdentity
   * @memberOf specifics.Account
   * @desc Cette directive permet de faire un check sur le champ du numéro de la pièce d'identité, en accordance avec la pièce d'identité sélectionnée dans le dropdown IDIdentity,
   * car chaque pièce d'identité a une regex différente associée.
   * @example <input type="text" name="accountCtrl.formData.IdentityNumber"
      label="@MLG["COMPTE_NumeroIdentite"]"
      ng-model="accountCtrl.formData.IdentityNumber"
      check-identity
      IdentityNumber-notification="@MLG["VALIDATOR_number"]"/>
   */
  /* @ngInject */
  .directive('checkIdentity', function ($timeout, CheckIdentityService) {
    return {
      restrict: 'A',
      require: 'ngModel',
      controllerAs: 'identityCtrl',
      link: async function (scope, element, attrs, modelCtrl) {
        const std = /^[A-Za-z0-9]+$/;
        let regex = std;
        const regexp = await CheckIdentityService.loadRegex(attrs.localization);

        const identitySelectElement = document.getElementById('IDIdentity');
        let inputText = identitySelectElement.options[identitySelectElement.selectedIndex].text.toUpperCase();

        refreshRegex();

        // On surveille le dropdown pour changer la regex associée au champ number
        identitySelectElement.onchange = (event) => {
          inputText = identitySelectElement.options[identitySelectElement.selectedIndex].text.toUpperCase();
          refreshRegex();
          refreshField();
        };

        // On met un validateur spé, qui va lancer la fonction validator
        modelCtrl.$validators.identity = validator;

        function refreshRegex() {
          regex = (regexp && inputText in regexp) ? new RegExp(regexp[inputText]) : std;
        }

        // Validateur qui valide l'input en fonction de la regex
        function validator(modelValue) {
          if (!modelValue) {
            return true;
          }
          return regex.test(modelValue);
        }

        function refreshField() {
          modelCtrl.$setValidity('identity', true);
          $timeout(function () {
            scope.$apply(function () {
              modelCtrl.$validate();
            });
          });
        }
      }
    };
  });
