export default angular
  .module('directive.mini-cart', [])
  .directive('miniCart', function () {
    return {
      restrict: 'E',
      bindToController: {
        isAddtocartNotlogged: '<'
      },
      controller: MiniCartController,
      controllerAs: 'miniCartCtrl'
    };
  });

class MiniCartController {
  /* @ngInject */
  constructor($scope, $element, $filter, $location, AppService, CartService, RoutesService, ModalService) {
    const ctrl = this;
    const tplTranslate = {};

    // Récupère les éléments à traduire
    $element
      .children('.translate')
      .children()
      .each(function () {
        const $this = $(this);
        tplTranslate[$this.attr('key')] = $this.html();
      });

    (async function () {
      try {
        await getCart();
      } catch (error) {
        console.error(error);
      }
    })();

    async function getCart(withRefresh) {
      try {
        ctrl.cartIsActive = true;
        const params = await AppService.getParams(withRefresh);

        // Vérifie si le panier est actif en fonction des paramètres
        if (params.Agent && !params.Mobility.Profile.AllModules.find(p => p.ModuleKey === 'sale')?.IsActive) {
          ctrl.cartIsActive = false;
        }

        // Détermine le chemin du panier en fonction de la localisation
        const cartPath = $location.path() !== `/${RoutesService.getUrlByName('QuickOrder')}` ? `/${RoutesService.getUrlByName('Cart')}` : `/${RoutesService.getUrlByName('QuickOrder')}`;

        // Vérifie s'il y a un panier et des produits
        ctrl.hasCart = params.HasCart && params.Cart && params.Cart.Products && params.Cart.Products.length;
        ctrl.cartLink = !ctrl.hasCart ? '' : cartPath;
        ctrl.showTTCPrice = params.Visitor.CardType.ShowTTCPrice;
        ctrl.addtocartOK = ctrl.isAddtocartNotlogged && !params.IsLogged ? false : true;
        ctrl.count = 0;

        if (ctrl.hasCart) {
          ctrl.data = params.Cart;

          // Marque les articles qui sont des cadeaux
          params.Cart.Products.forEach(line => {
            if (params.Cart.TradeActions.find(tradeAction => tradeAction.IDProduct === line.IDProduct)) {
              line.isGift = true;
            }
          });

          // Trie les articles dans le panier
          ctrl.cartProducts = $filter('orderBy')(
            params.Cart.Products.filter(line => !line.isGift),
            'LineNumber'
          );

          const count = parseFloat(ctrl.cartProducts.reduce((result, line) => result + line.Quantity, 0));
          ctrl.count = count.toFixed(count % 1 === 0 ? 0 : 2);
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (!$scope.$$phase) {
          $scope.$digest();
        }
      }
    }

    // Écouteur d'événement pour la mise à jour de la connexion
    $scope.$on('logUpdate', (event, value) => {
      ctrl.addtocartOK = ctrl.isAddtocartNotlogged && !value ? false : true;
    });

    // Fonction pour vider le panier
    ctrl.emptyCart = () => {
      ModalService.show('/Template/Cart/ModalClearCartConfirm', {}, null, 'clearCartConfirmModalCtrl');
    };

    // Fonction pour supprimer une ligne du panier
    ctrl.remove = line => {
      const product = {
        idProduct: line.Product.IDProduct,
        quantity: line.Quantity,
        Price: {
          TTCPrice: line.TTCAmount
        }
      };

      ModalService.show(
        '/Template/Cart/ModalDeleteConfirm',
        {
          action: () => {
            CartService.deleteLine(line.IDLine, product, line.Product.Designation, line.Product.IDPicture, line.Product.DistrimageSrc, tplTranslate.titleDeleteOk);
          }
        },
        null,
        'deleteConfirmModalCtrl'
      );
    };

    // Écouteur d'événement pour la mise à jour du panier
    $scope.$on('cartUpdate', async () => {
      try {
        await getCart(false);
      } catch (error) {
        console.error(error);
      }
    });

    // Écouteur d'événement pour la mise à jour du panier avec rafraîchissement
    $scope.$on('cartUpdateWithRefresh', async () => {
      try {
        await getCart(true);
      } catch (error) {
        console.error(error);
      }
    });
  }
}

/* @ngInject */
angular.module('directive.mini-cart')
  .controller('deleteConfirmModalCtrl', function (data, device, options, ModalService) {
    const ctrl = this;

    ctrl.submit = () => {
      ModalService.close();
      if (data.action) {
        data.action();
      }
    };

    ctrl.cancel = () => {
      ModalService.close();
      if (data.cancel) {
        data.cancel();
      }
    };
  });

/* @ngInject */
angular.module('directive.mini-cart')
  .controller('clearCartConfirmModalCtrl', function (data, $rootScope, HttpService, AppService, ModalService, toastr, GA4Service, NetwaveService) {
    const ctrl = this;

    ctrl.submit = async () => {
      ModalService.close();
      $rootScope.$broadcast('showPageLoader', true);

      try {
        const response = await HttpService.post({
          url: 'Cart/ClearCart',
          data: {}
        });

        // GA4 Remove From Cart
        if (response.status === 'OK') {
          try {
            const currency = response?.results?.Currency?.ISOCode || null;
            GA4Service.removeFromCart([], response?.removedProducts, currency, response?.results?.Localization?.TradeName);
          } catch (error) {
            console.error(error);
          }
        }

        const { messages, errors } = await AppService.getTranslate();

        if (response.status === 'OK') {
          await NetwaveService.emptyCart(response.removedProducts);
          AppService.updateParams(response.results);
          toastr.success('', messages.ClearCartOK, {
            allowHtml: true,
            extraData: {
              template: 'import/js/libs/angular-toastr/toast_message.tpl'
            }
          });
          if (data.action) {
            data.action();
          }
        } else {
          toastr.warning(errors.TryLater, errors.ErrorHasOccurred, {
            allowHtml: true,
            extraData: {
              template: 'import/js/libs/angular-toastr/toast_message.tpl'
            }
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        $rootScope.$broadcast('showPageLoader', false);
        if (!$rootScope.$$phase) {
          $rootScope.$digest();
        }
      }
    };
  });
