export default angular
  .module('module.product', [])

  /* @ngInject */
  .directive('productDetail', function () {
    return {
      restrict: 'E',
      scope: false,
      bindToController: {
        id: '<idProduct',
        url: '@productUrl',
        idGroup: '<idProductGroup',
        urlGroup: '@productGroupUrl',
        isPrimary: '<',
        isKit: '<',
        isGiftCard: '<',
        showComponents: '<',
        allowAddToCart: '<',
        customizablePrice: '<',
        customizablePriceTooltip: '@',
        min: '<',
        step: '<',
        price: '<productPrice',
        designation: '@productDesignation',
        img: '<productImg',
        modalCtrl: '<',
        device: '<'
      },
      controllerAs: 'productCtrl',
      /* @ngInject */
      controller: function ($scope, $injector) {
        const ctrl = this;

        ctrl.loaded = false;
        ctrl.attributes = [];
        ctrl.quantity = 1;
        ctrl.costTTC = 0;
        ctrl.costHT = 0;
        ctrl.customizationCostTTC = 0;
        ctrl.customizationCostHT = 0;

        ctrl.$onInit = async () => {
          const response = await import(/* webpackChunkName: "product" */ 'Modules/Product/import/product.js');
          $injector.loadNewModules([response.default.name]);
          const service = $injector.get('ImportProduct');
          service.initModule(ctrl, $scope);
        };

        // Fonction pemettant le scroll vers un élément au sein de la modal.
        ctrl.scrollToElement = function (id, topOffset) {
          // Ciblage de l'ensemble de la modal, arrière-plan compris.
          const modal = angular.element('.modal');
          // Ciblage du conteneur informatif de la modal.
          const modalContent = angular.element('.modal-dialog');
          const modalContentPosition = modalContent.offset().top;
          const modalContentOffsetTop = modalContent.prop('offsetTop');
          const targetElement = angular.element((`#${id}`));
          const targetElementPosition = targetElement.offset().top;

          modal.animate({
            scrollTop: targetElementPosition - modalContentPosition + modalContentOffsetTop - topOffset
          }, 500);
        };
      }
    };
  })

  /* @ngInject */
  .directive('productAdvice', function () {
    return {
      restrict: 'E',
      scope: false,
      controllerAs: 'productCtrl',
      /* @ngInject */
      controller: function ($scope, $injector) {
        const ctrl = this;

        ctrl.$onInit = async () => {
          const response = await import(/* webpackChunkName: "product" */ 'Modules/Product/import/product.js');
          $injector.loadNewModules([response.default.name]);
        };
      }
    };
  })

  /* @ngInject */
  .directive('productRecipe', function () {
    return {
      restrict: 'E',
      scope: false,
      controllerAs: 'productCtrl',
      /* @ngInject */
      controller: function ($scope, $injector) {
        const ctrl = this;

        ctrl.$onInit = async () => {
          const response = await import(/* webpackChunkName: "product" */ 'Modules/Product/import/product.js');
          $injector.loadNewModules([response.default.name]);
        };
      }
    };
  })

  /* @ngInject */
  .directive('productJobs', function () {
    return {
      restrict: 'E',
      scope: false,
      bindToController: {
        designation: '@',
        ref: '@',
        date: '@',
        ville: '@',
        department: '@'
      },
      controllerAs: 'productCtrl',
      /* @ngInject */
      controller: function ($scope, $injector) {
        const ctrl = this;

        ctrl.$onInit = async () => {
          const response = await import(/* webpackChunkName: "product" */ 'Modules/Product/import/product.js');
          $injector.loadNewModules([response.default.name]);
        };

        ctrl.linkForm = '/jobs?view=JobsForm';
      }
    };
  });
