export default angular
  .module('service.account.invoices', [])

  /* @ngInject */
  .factory('AccountInvoicesService', function (APIService, HttpService, RoutesService) {
    const service = {
      getInvoicesToPayFromBack,
      getInvoicesToPay,
      getCredit,
      getPaymentMethods,
      postTransaction
    };
    return service;

    function getInvoicesToPay(cardID) {
      return APIService.$get(`standard/payment/${cardID}/documents`, {}, true)
        .then(data => data && data.filter(i => i.documentType === 'FACTURE'));
    }

    function getInvoicesToPayFromBack(cardID) {
      const url = RoutesService.getUrlByName('AccountGetUnpaidInvoices');

      //on passe par le back pour appeler l'API car il a possiblement les données en cache
      return HttpService.get({
        url,
        params: { cardID },
        cache: false //pas de cache ici car géré côté back
      });
    }

    function getCredit(cardID) {
      return APIService.$get(`standard/customer/customers/${cardID}/available-amounts`, {}, true);
    }

    function getPaymentMethods() {
      const url = RoutesService.getUrlByName('AccountGetInvoicesPaymentMethods');

      return HttpService.get({
        url,
        cache: false
      });
    }

    function postTransaction(documentIds) {
      return APIService.$post('standard/payment/documents/transaction', { documentIds });
    }
  });
