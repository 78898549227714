export default angular
  .module('directive.visibleonscroll', [])

  .directive('visibleOnScroll', function () {
    return {
      restrict: 'A',
      bindToController: {
        offset: '<'
      },
      controllerAs: '$ctrl',
      /* @ngInject */
      controller: function ($timeout, WindowEventsService) {
        const ctrl = this;

        ctrl.$onDestroy = () => {
          WindowEventsService.listen(false, 'resize', handler);
        };

        WindowEventsService.listen(true, 'scroll', handler, 500);

        function handler() {
          const sc = isNaN(window.pageYOffset) ? document.documentElement.scrollTop : window.pageYOffset;
          $timeout(() => {
            ctrl.active = sc > ctrl.offset;
          });
        }
      }
    };
  });
