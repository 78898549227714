import 'Modules/Capency/components/component.cap-validate-email.tpl';
import 'Modules/Capency/components/component.cap-validate-email.less';
import { CapDropdown } from '../helpers/capdropdown';

/**
 * @class angular.capadresse.capValidateEmail
 * @desc Composant angularJS : validate and propose valid email alternatives.
 * @memberOf angular.capadresse
 */
/* @ngInject */
class CapValidateEmail extends CapDropdown {
  constructor($scope, $element, $transclude, $timeout, capencyService, tooltipMessageService) {

    if (!capencyService.isCapEmailEnable()) return;

    super($scope, $element, $transclude, $timeout);
    this.capService = capencyService;

    this.showWait = function () {
      tooltipMessageService.showWarningWait($element);
    };

    this.hideWait = function () {
      tooltipMessageService.hideWarningWait($element);
    };

    this.parentCtrl = function () {
      if (this.scope.$parent.newClientCtrl) {
        return this.scope.$parent.newClientCtrl;
      }
      return this.scope.$ctrl;
    };
  }

  onBlur() {
    if (this.userInteracted === true && !this.modelCtrl.$error.required && !this.modelCtrl.$error.email && !this.modelCtrl.$error.strictemail) {
      this.getOptions();
      this.userInteracted = false;
    }

    if (this.targeted) {
      return;
    }

    this.timeout(() => {
      this.scope.$apply(() => {
        this.opened = false;
      });
    });
  }

  $onInit() {
    this.initField();
    this.parentCtrl().capValidated = false;
    this.input.setAttribute('cap-validate-notification', this.invalidMessage);
  }

  /**
   * When form is already filled, link with capAdress Values
   */
  initField() {
    if (!this.modelCtrl.$modelValue) {
      return;
    }

    this.selection = { email: this.modelCtrl.$modelValue };

    this.modelCtrl.$viewChangeListeners.unshift(() => {
      const afterSelection = this.modelCtrl.$viewValue === this.parseOption(this.selection);

      if (afterSelection || this.cleared) {
        return;
      }
    });
  }

  /**
   * Validate and fetch list of valid email alternatives from CapEmail
   * @param {string} userInput
   */
  async fetchList(userInput) {
    this.hasSuggestions = false;
    this.parentCtrl().capValidated = false;
    this.showWait();

    //CkeckMailProp countryId parameter always 1 (france)
    const result = await this.capService.checkMailProp(userInput, 1);
    let list = null;
    if (result !== null) {
      list = [angular.fromJson('{ "sMail": "val" }'.replace('val', userInput))];
      if (result.iRet && result.iRet === '0') {
        this.modelCtrl.$setValidity('cap-validate', true);
        this.parentCtrl().capValidated = true;
        this.close();
        list = [];
      } else if (result.iRet && result.iRet === '2') {
        this.modelCtrl.$setValidity('cap-validate', false);
        this.hasSuggestions = true;
        list = list.concat(result.Mail);
      } else {
        this.modelCtrl.$setValidity('cap-validate', false);
      }
    }
    this.hideWait();
    return list;
  }

  onKeyDown(event) {
    if (this.disabled) {
      return;
    }
    this.userInteracted = true;
    return;
  }

  /**
   * User can force any valid/invalid email
   * @param {string} userInput
   */
  onSelection(value, index) {
    this.select(value, index);
    this.modelCtrl.$setValidity('cap-validate', true);
    this.parentCtrl().capValidated = true;
    this.opened = false;
  }

  /**
   * What to display from the option on the dropdown
   * @param {CapAddressOption} option
   */
  displayOption(option) {
    return option.sMail;
  }

  /**
   * Parse the value to save in the ngModel from the capAdress result
   * @param {CapAddressOption} option
   */
  parseOption(option) {
    if (option === undefined) {
      return;
    }

    return option.sMail;
  }

  /**
   * Interface to other cap fields that can update this one's value
   * @param {CapAddressOption} option
   * @param {number} index optionnal: index of the option in options list
   */
  setOption(option, index) {
    super.select(option, index);
  }

  /**
   * Finds in select's options list, the index of given value
   * @param {Object} value
   */
  findIndex(value) {
    return this.options.findIndex(option => {
      if ('sMail' in option) {
        return option.sMail === value.sMail;
      }

      // Fallback
      return this.parseOption(option) === this.parseOption(value);
    });
  }
}

export default angular
  .module('component.capValidateEmail', [])
  .controller('capValidateEmailCtrl', CapValidateEmail)
  .component('capValidateEmail', {
    templateUrl: 'Modules/Capency/components/component.cap-validate-email.tpl',
    transclude: true,
    controller: 'capValidateEmailCtrl',
    bindings: {
      invalidMessage: '@?',
      optionsTitle: '@?',
      optionsTitleWarning: '@?',
      optionsConfirm: '@?',
      optionsSelect: '@?'
    }
  });
