export default angular
  .module('controller.newsletter', [])

  /* @ngInject */
  .controller('newsletterController', function (
    $rootScope,
    $element,
    $timeout,
    HttpService,
    AppService,
    LogService,
    ModalService,
    toastr
  ) {
    const ctrl = this;

    ctrl.formData = {};

    ctrl.submit = async () => {
      ctrl.loading = true;

      try {
        // Obtenir le token de sécurité des formulaires
        ctrl.formData.Token = $("input:hidden[name='securitytoken_newsletter']").val();

        const response = await HttpService.post({
          url: '/Newsletter/Subscribe',
          data: ctrl.formData
        });

        const { messages, errors } = await AppService.getTranslate();

        // Gérer la réponse en fonction du statut
        if (response.status === 'OK') {
          // Marquer le formulaire comme soumis avec succès
          ctrl.submitted = true;

          // Afficher un message de succès
          toastr.success('', messages.NewsletterOK, {
            allowHtml: true,
            extraData: {
              template: 'import/js/libs/angular-toastr/toast_message.tpl'
            }
          });

          // Réinitialiser le formulaire après un délai
          $timeout(() => {
            ctrl.form.$hideValidation();
            $element.find('.form-control').blur();
            ctrl.formData.Email = '';
            ctrl.form.$resetValidation();
          }, 2000);
        } else if (response.status === 'ERROR_CONNECTION_EXPECTED') {
          // Rediriger vers l'authentification en cas d'erreur de connexion
          LogService.redirect = { url: null };
          ModalService.show(
            '/Template/Authentication/ModalAuthentication',
            {
              newsletterConnect: true,
              mailRecognized: ctrl.formData.Email
            },
            null,
            'loginModalCtrl'
          );
        } else if (response.status === 'REGISTRATION_ALREADY_EXISTS') {
          // Afficher un message de réussite si l'utilisateur est déjà inscrit
          toastr.success('', messages.NewsletterDejaOK, {
            allowHtml: true,
            extraData: {
              template: 'import/js/libs/angular-toastr/toast_message.tpl'
            }
          });
        } else {
          // Afficher les erreurs de validation
          Object.keys(response.errors).forEach(key => {
            let msg = response.errors[key].Errors[0].ErrorMessage;
            if (errors[msg] !== undefined) {
              msg = errors[msg];
            }
            toastr.warning(msg, errors.ErrorHasOccurred, {
              allowHtml: true,
              extraData: {
                template: 'import/js/libs/angular-toastr/toast_message.tpl'
              }
            });
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        // Désactiver le chargement
        ctrl.loading = false;

        !$rootScope.$$phase && $rootScope.$digest();
      }
    };
  });
