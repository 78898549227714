import 'Modules/Shared/Comparator/import/toast_comparator.tpl';

export default angular
  .module('directive.compare.checkbox', [])

  /* @ngInject */
  .directive('compareCheckbox', function () {
    return {
      restrict: 'E',
      scope: true,
      bindToController: {
        id: '<idProduct',
        img: '<productImg',
        designation: '@productDesignation',
        titleAdd: '@',
        titleRemove: '@',
        titleFull: '@',
        device: '<'
      },
      controllerAs: 'ctrl',
      /* @ngInject */
      controller: function ($scope, $rootScope, toastr, CompareService) {
        const ctrl = this;

        ctrl.$onInit = () => {
          getChecked();
        };

        ctrl.$onDestroy = () => {
          unwatch();
          unwatchEvent();
        };

        ctrl.click = event => {
          event.stopPropagation();
          event.preventDefault();
        };

        const unwatch = $scope.$watch('checked', value => {
          if (!ctrl.init) {
            ctrl.init = true;
            return;
          }
          if (value) {
            if (ctrl.products.length === CompareService.maxItems) {
              $scope.checked = false;
              toastr.warning('', ctrl.titleFull, {
                allowHtml: true,
                extraData: {
                  template: 'import/js/libs/angular-toastr/toast_message.tpl'
                }
              });
              return;
            }
            CompareService.add(ctrl.id);
            toastr.success(ctrl.designation, ctrl.titleAdd, {
              allowHtml: true,
              extraData: {
                template: 'Modules/Shared/Comparator/import/toast_comparator.tpl',
                data: {
                  img: ctrl.img
                }
              }
            });
          } else {
            if (!ctrl.products.length) return;
            if (!CompareService.get().includes(ctrl.id)) return;
            CompareService.remove(ctrl.id);
            toastr.success(ctrl.designation, ctrl.titleRemove, {
              allowHtml: true,
              extraData: {
                template: 'Modules/Shared/Comparator/import/toast_comparator.tpl',
                data: {
                  img: ctrl.img
                }
              }
            });
          }
          $scope.$emit('comparatorCheckboxUpdate');
          if (value && ctrl.device.mobile && ctrl.device.ios) {
            $rootScope.$broadcast('openComparator');
          }
        });

        const unwatchEvent = $scope.$on('comparatorUpdate', () => {
          getChecked();
        });

        // Functions

        function getChecked() {
          ctrl.products = CompareService.get();
          $scope.checked = ctrl.products.includes(ctrl.id);
        }
      }
    };
  });
