export default angular.module('service.capency', []).factory('capencyService', capencyService);

/**
 * @class angular.capency.capencyService
 * @memberOf angular.capency
 */
/* @ngInject */
function capencyService(HttpService) {
  /**
   * @constant
   * @type {Set<string>}
   * @memberOf angular.capency.capencyService#
   * @desc List of countries that have a specific behaviour on capadress
   */
  const specificCountries = new Set(['27', '215']); // eslint-disable-line no-undef

  let capencyInfos;

  const initializeCapencyInfos = async function () {
    if (!capencyInfos) {
      capencyInfos = await getCapencyInfos();
    }
  };

  const getCapencyInfos = async function () {
    return HttpService.get({
      url: '/Capency/GetCapencyInfos',
      cache: true
    })
      .catch(err => {
        console.log(err);
      });
  };

  const isCapAdressEnable = async function () {
    await initializeCapencyInfos();
    return capencyInfos === null ? false : capencyInfos.CapAdresseEnable;
  };

  const isCapEmailEnable = async function () {
    await initializeCapencyInfos();
    return capencyInfos === null ? false : capencyInfos.CapEmailEnable;
  };

  const isCapPhoneEnable = async function () {
    await initializeCapencyInfos();
    return capencyInfos === null ? false : capencyInfos.CapPhoneEnable;
  };


  return {
    specificCountries,
    fetchLocalities,
    fetchStreets,
    fetchStreetNumbers,
    checkMailProp,
    validateForm,
    validatePhone,
    isCapAdressEnable,
    isCapEmailEnable,
    isCapPhoneEnable
  };


  /**
   * @func
   * @desc Fetches a list of localities from CapAddress
   * @memberOf angular.capency.capencyService#
   * @param {string} userInput
   * @param {number} country country identifier
   * @param {boolean} inline wether localities chall be fetched from capAddress line api or search api
   */
  async function fetchLocalities(userInput, country, inline = false) {
    if (!(await isCapAdressEnable())) return;
    const path = inline ? 'LocalityLine' : 'SearchLocality';
    return HttpService.get({
      url: `/CapAddress/${path}`,
      params: {
        input: userInput,
        countryId: country
      },
      keepDataObject: true
    })
      .then(({ data }) => data.localities)
      .catch(err => {
        if (err && err.status === 403) {
          return [];
        }

        return;
      });
  }

  /**
   * @func
   * @desc Fetches a list of streets from CapAddress
   * @memberOf angular.capency.capencyService#
   * @param {string} userInput
   * @param {Object} option Fields from CapAdress response addresses, representing currently selected locality
   * @param {boolean} inline wether localities chall be fetched from capAddress line api or search api
   */
  async function fetchStreets(userInput, { country, postalCode, locality, localityId }, inline = false) {
    if (!(await isCapAdressEnable())) return;
    const path = inline ? 'StreetLine' : 'SearchStreet';
    return HttpService.get({
      url: `/CapAddress/${path}`,
      params: {
        input: userInput,
        countryId: country,
        locality,
        localityId,
        postalCode
      },
      keepDataObject: true
    })
      .then(({ data }) => data.streets)
      .catch(err => {
        if (err && err.status === 403) {
          return [];
        }

        return;
      });
  }

  /**
   * @func
   * @memberOf angular.capency.capencyService#
   * @desc Fetch the list of available numbers for currently selected street
   * @param {string} userInput
   * @param {Object} option Fields from capAddress responses, representing currently selected street
   */
  async function fetchStreetNumbers(userInput, { countryId, localityId, streetId }) {
    if (!(await isCapAdressEnable())) return;
    return HttpService.get({
      url: '/CapAddress/SearchStreetNumbers',
      params: {
        input: userInput || '',
        countryId,
        localityId,
        streetId
      },
      keepDataObject: true
    })
      .then(({ data }) => data.numbers)
      .catch(err => {
        if (err && err.status === 403) {
          return [];
        }

        return;
      });
  }

  /**
   * @func
   * @desc Check and return a list of valid email alternatives from CapEmail
   * @memberOf angular.capency.capencyService#
   * @param {string} userInput
   * @param {number} country country identifier
   */
  async function checkMailProp(userInput, country) {
    try {
      if (!(await isCapEmailEnable())) return;
      const data = await HttpService.get({
        url: '/CapEmail/CheckMailProp',
        params: {
          input: userInput,
          countryId: country
        }
      });
      return data.checkMailProp;
    } catch (error) {
      if (error && error.status === 403) {
        return [];
      }
      return;
    }
  }

  /**
   * @func
   * @memberOf angular.capency.capencyService#
   * @desc CapAdress validation api call to perform once when the form is submitted
   * @param {Object} address Representation of currently selected address
   */
  async function validateForm(address) {
    return HttpService.post({
      url: '/CapAddress/ValidateForm',
      data: address,
      json: true,
      keepDataObject: true
    });
  }


  /**
 * @func
 * @desc Check if a phone number syntax is valid
 * @memberOf angular.capency.capencyService#
 * @param {string} userInput
 * @param {number} country country identifier
 */
  async function validatePhone(userInput, country, isMobile) {
    try {
      if (!(await isCapPhoneEnable())) return;
      const data = await HttpService.get({
        url: '/CapPhone/CheckPhoneNumber',
        params: {
          input: userInput,
          countryId: country,
          isMobile
        }
      });
      return data;
    } catch (error) {
      if (error && error.status === 403) {
        return [];
      }
      return;
    }
  }

}
