export default angular
  .module('service.http', [])

  /* @ngInject */
  .factory('HttpService', function ($http) {
    const defaultHttpHeaders = {
      'Content-type': 'application/json',
      'X-Requested-With': 'jsonHttpRequest'
    };

    return {
      get: object =>
        $http({
          method: 'GET',
          //Permet d'envoyer des données dans la query d'une requete get (format '?param=value')
          params: object.params,
          //URL à appeler
          url: object.url,
          //Détermine si la requête est mise en cache
          cache: object.cache,
          //Headers par défaut de la requête
          headers: defaultHttpHeaders,
          //Durée en millisecondes ou une promesse qui mettra fin à l'appel
          timeout: object.timeout
        })
          .then(response => (object.keepDataObject ? response : response.data))
          .catch(error => {
            throw error;
          }),
      post: object =>
        $http({
          method: 'POST',
          //Données à transmettre pour le post
          data: object.data,
          //URL à appeler
          url: object.url,
          //Headers par défaut de la requête
          headers: defaultHttpHeaders,
          //Durée en milliseconde ou une promesse qui mettra fin à l'appel
          timeout: object.timeout
        })
          .then(response => (object.keepDataObject ? response : response.data))
          .catch(error => {
            throw error;
          }),
      put: object =>
        $http({
          method: 'PUT',
          //Données à transmettre pour le put
          data: object.data,
          //URL à appeler
          url: object.url,
          //Headers par défaut de la requête
          headers: defaultHttpHeaders,
          //Durée en milliseconde ou une promesse qui mettra fin à l'appel
          timeout: object.timeout
        })
          .then(response => (object.keepDataObject ? response : response.data))
          .catch(error => {
            throw error;
          }),
      patch: object =>
        $http({
          method: 'PATCH',
          //Données à transmettre pour le patch
          data: object.data,
          //URL à appeler
          url: object.url,
          //Headers par défaut de la requête
          headers: defaultHttpHeaders,
          //Durée en milliseconde ou une promesse qui mettra fin à l'appel
          timeout: object.timeout
        })
          .then(response => (object.keepDataObject ? response : response.data))
          .catch(error => {
            throw error;
          }),
      delete: object =>
        $http({
          method: 'DELETE',
          //Permet d'envoyer des données dans la query d'une requete get (format '?param=value')
          params: object.params,
          //URL à appeler
          url: object.url,
          //Headers par défaut de la requête
          headers: defaultHttpHeaders,
          //Durée en millisecondes ou une promesse qui mettra fin à l'appel
          timeout: object.timeout
        })
          .then(response => (object.keepDataObject ? response : response.data))
          .catch(error => {
            throw error;
          })
    };
  })

  /* @ngInject */
  .factory('APIService', function ($http, AppService) {
    const defaultAPIHeaders = { 'Content-type': 'application/json', 'X-Requested-With': 'apiRequest' };

    let baseURL;
    let APIToken;
    let isAPIActive = true;

    //promesse de récupération de l'URL de l'API
    const urlPromise = AppService.getParams()
      .then(async function (data) {
        baseURL = data.Api.URLV2;
        await _auth();
      }).catch(function (err) {
        console.error(err);
        isAPIActive = false;
      });

    const service = {
      $get: _get,
      $post: _post,
      $put: _put,
      $patch: _patch,
      $delete: _delete
    };
    return service;

    ////////////

    /**
     * Fonction permettant d'effectuer un appel GET vers l'API OceanV2
     * @param {string} url L'URL à appeler
     * @param {any[]} params Les éventuels query parameters
     */
    function _get(url, params, cache = false) {
      return _request({
        method: 'GET',
        url,
        params,
        cache
      });
    }

    /**
     * Fonction permettant d'effectuer un appel POST vers l'API OceanV2
     * @param {string} url L'URL à appeler
     * @param {any} data Le payload à envoyer
     */
    function _post(url, data) {
      return _request({
        method: 'POST',
        url,
        data
      });
    }

    /**
     * Fonction permettant d'effectuer un appel PUT vers l'API OceanV2
     * @param {string} url L'URL à appeler
     * @param {any} data Le payload à envoyer
     */
    function _put(url, data) {
      return _request({
        method: 'PUT',
        url,
        data
      });
    }

    /**
     * Fonction permettant d'effectuer un appel PATCH vers l'API OceanV2
     * @param {string} url L'URL à appeler
     * @param {any} data Le payload à envoyer
     */
    function _patch(url, data) {
      return _request({
        method: 'PATCH',
        url,
        data
      });
    }

    /**
     * Fonction permettant d'effectuer un appel DELETE vers l'API OceanV2
     * @param {string} url L'URL à appeler
     * @param {any[]} params Les éventuels query parameters
     */
    function _delete(url, params) {
      return _request({
        method: 'DELETE',
        url,
        params
      });
    }

    /**
     * Fonction principale de requêtage vers l'API OceanV2
     * @param {any} config Objet de cofiguration de l'appel
     */
    async function _request(config, isURLWhole) {
      if (!isAPIActive) {
        return;
      }

      config.headers = defaultAPIHeaders;

      //si l'URL n'est pas set, on attend la promesse de récupération
      if (!baseURL) {
        await urlPromise;
      }

      //si on revient d'une authentification, l'objet de config
      //possède déjà l'URL complète
      if (!isURLWhole) {
        config.url = baseURL + config.url;
      }

      if (isAPIActive && APIToken) {
        return $http(config)
          .then(onSuccess, onError)
          .then(function (result) {
            return result;
          });
      }

      return;
    }

    /**
     * Fonction de callback si succès de l'appel API
     * @param {any} httpResponse La réponse de l'API
     */
    function onSuccess(httpResponse) {
      return httpResponse.data;
    }

    /**
     * Fonction de callback si erreur de l'appel API (status code 300+)
     * @param {any} httpResponse La réponse de l'API
     */
    async function onError(httpResponse) {
      if (httpResponse.status === 401) {
        await _auth();
        return _request(httpResponse.config, true);
      }

      printAPIErrorResponse(httpResponse);
      throw Error();
    }

    /**
     * Fonction de récupération auprès du back du token d'authentification à l'API
     */
    async function _auth() {
      return $http({
        method: 'GET',
        url: 'api/auth'
      }).then(
        function (response) {
          APIToken = response.data;

          const authHeader = { Authorization: `Bearer ${APIToken}` };
          angular.extend(defaultAPIHeaders, authHeader);

          return true;
        },
        function (response) {
          if (response.status === 406) {
            isAPIActive = false;
          }

          printAPIErrorResponse(response);
          return false;
        }
      );
    }

    /**
     * Fonction permettant d'imprimer de manière formatée les erreurs retournées
     * @param {any} httpResponse L'obet de réponse de la requête échouée
     */
    function printAPIErrorResponse(httpResponse) {
      let errorMessage = `${httpResponse.statusText} (${httpResponse.status})`;
      const headers = httpResponse.headers();

      if (httpResponse.data) {
        if (httpResponse.data.title && httpResponse.data.detail) {
          errorMessage += ` - ${httpResponse.data.title} \n\n${httpResponse.data.detail}`;
        } else {
          errorMessage += ` - ${headers ? `${headers['status-description']} => ` : ''}${httpResponse.data}`;
        }
      }

      console.error(errorMessage);
    }
  });
