export default angular
  .module('directive.capPlace', [])
  /**
   * @class angular.capadresse.capPlace
   * @memberOf angular.capadresse
   * @desc Directive : À ajouter sur un champ 'lieu dit', permet la completion automatique par capadress.
   */
  .directive('capPlace', function () {
    return {
      restrict: 'A',
      require: ['ngModel', '?^^capForm'],
      link: (scope, _elt, _attrs, controllers) => {
        const [modelCtrl, formCtrl] = controllers;

        if (!formCtrl) {
          return;
        }

        // When place/location is hidden behind a ngIf of uibDropdown
        if ('capPlace' in formCtrl) {
          setValue(formCtrl.capPlace.value);
        }

        formCtrl.capPlace = {
          setValue,
          clear
        };

        /**
         * Set ngModel value to fill the field
         * @param {string} value
         */
        function setValue(value) {
          modelCtrl.$setViewValue(value);
          modelCtrl.$render();
        }

        /**
         * Programatically clear the field;
         */
        function clear() {
          modelCtrl.$setViewValue('');
          modelCtrl.$render();
        }
      }
    };
  });
