export default angular
  .module('module.gtm', [])

  /* @ngInject */
  .factory('GtmService', function ($cookies, DateService) {
    const service = {
      inject
    };
    return service;

    ////////////
    function inject(key) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = getContent(key);
      $('head')[0].appendChild(script);
      $cookies.put('gtmOK', 'true', {
        path: '/',
        expires: DateService.nextYear()
      });
    }

    function getContent(key) {
      /* eslint-disable */
      return `(function (w, d, s, l, i) {w[l] = w[l] || []; w[l].push({'gtm.start':new Date().getTime(), event: 'gtm.js'}); var f = d.getElementsByTagName(s)[0],j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src ='https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);})(window, document, 'script', 'dataLayer', '${key}');`;
      /* eslint-enable */
    }
  })

  /* @ngInject */
  .directive('gtm', function () {
    return {
      restrict: 'A',
      bindToController: {
        content: '@'
      },
      /* @ngInject */
      controller: function (GtmService) {
        const ctrl = this;

        ctrl.$onInit = () => {
          GtmService.inject(ctrl.content);
        };
      }
    };
  });
