export default angular
  .module('component.addtowishlist', [])

  .component('buttonAddToWishlist', {
    bindings: {
      isProductButton: '<?',
      id: '<idProduct',
      isPrimary: '<',
      url: '@productUrl',
      titleAdd: '@',
      titleRemove: '@',
      tooltipAdd: '@',
      tooltipYet: '@',
      tooltipChoice: '@?',
      message: '@',
      device: '<'
    },
    template: '',
    /* @ngInject */
    controller: function ($scope, $element, $injector, $compile) {
      const ctrl = this;

      ctrl.$onInit = () => {
        loadTemplate();
      };

      async function loadTemplate() {
        const response = await import(/* webpackChunkName: "addtowishlist" */ 'import/js/components/addtowishlist/component.addtowishlist.html');
        $element.html(response.default);
        $compile($element.contents())($scope);
        init();
      }

      async function init() {
        const response = await import(/* webpackChunkName: "addtowishlist.init" */ 'import/js/components/addtowishlist/component.addtowishlist.init.js');
        $injector.loadNewModules([response.default.name]);
        const service = $injector.get('InitAddtowishlist');
        service.init(ctrl, $scope);
      }
    }
  });
