export default angular
  .module('directive.capWarningMobile', [])
  /**
   * @class angular.capency.capWarningMobile
   * @memberOf angular.capency
   * @desc Directive : Show warning message if number is not mobil phone, only for France.
   */
  .directive('capWarningMobile', function (PhoneService) {
    return {
      restrict: 'A',
      require: ['ngModel', '?^^capForm'],
      link: function (scope, element, _attrs, controllers) {
        const [modelCtrl, formCtrl] = controllers;

        if (!formCtrl) {
          return;
        }

        formCtrl.capWarningMobile = {
          clear: function () {
            hideWait();
            modelCtrl.$setViewValue('');
            modelCtrl.$render();
          }
        };

        element.bind('blur', async function (e) {
          if (e.target.value) {
            try {
              hideWait();
              showWait();
              const idCountry = formCtrl.capCountry.getSelectedCountryId();
              const isValid = await PhoneService.isValidPhone(e.target.value, idCountry, true);
              modelCtrl.$setValidity('capvalidate', isValid);
              hideWait();
            } catch (ex) {
              console.error('CapWarningMobile - ValidateMobile', ex);
              hideWait();
            }
          }
        });

        function showWait() {
          scope.showLoader = true;
        }

        function hideWait() {
          scope.showLoader = false;
        }

      }
    };
  });
