export default angular
  .module('directive.stop.propagation', [])

  /* @ngInject */
  .directive('stopPropagation', function () {
    return {
      restrict: 'A',
      link: (scope, element, attrs) => {
        element.click(event => {
          event.stopPropagation();
          if (attrs.preventDefault) {
            event.preventDefault();
          }
        });
        scope.$on('$destroy', () => {
          element.off('click');
        });
      }
    };
  });
